import React, { Component } from "react";
import "../style.css";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import {
  fetchNotificationPreferences,
  updateNotificationPreferences,
} from "../../../actions/notification";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";

class SettingsDashboard extends Component {
  state = {
    notifications: this.initializeNotifications(),
    smsDetails: {
      phoneNumber:
        this.props.notificationPreferences?.data?.items[0]?.phone_number || "",
    },
    selectedCategory: null,
    showAlert: false,
    errorMessage: null,
    isModified: false,
  };

  initializeNotifications() {
    const preferences = this.props.notificationPreferences?.data?.items || [];

    const defaultNotifications = {
      status_change: { web: false, email: false, sms: false },
      release_note: { web: false, email: false, sms: false },
    };

    preferences.forEach((pref) => {
      if (defaultNotifications[pref.notification_event]) {
        defaultNotifications[pref.notification_event] = {
          web: !!pref.notify_wattmaestro,
          email: !!pref.notify_email,
          sms: !!pref.notify_sms,
        };
      }
    });

    return defaultNotifications;
  }

  componentDidMount() {
    this.props.fetchNotificationPreferences();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.notificationPreferences?.data?.items !==
      this.props.notificationPreferences?.data?.items
    ) {
      this.setState({
        notifications: this.initializeNotifications(),
        smsDetails: {
          phoneNumber:
            this.props.notificationPreferences?.data?.items[0]?.phone_number ||
            "",
        },
      });
    }
  }

  toggleCheckbox = (category, type) => {
    if (type === "sms") {
      this.setState({
        selectedCategory: category,
      });

      const smsSelected = !this.state.notifications[category].sms;
      if (smsSelected && !this.state.smsDetails.phoneNumber) {
        this.setState({
          errorMessage: "Phone number is required for SMS notifications.",
        });
        return;
      }
    }

    this.setState((prevState) => ({
      notifications: {
        ...prevState.notifications,
        [category]: {
          ...prevState.notifications[category],
          [type]: !prevState.notifications[category][type],
        },
      },
      isModified: true,
      errorMessage: null,
    }));
  };

  handleInputChange = (field, value) => {
    this.setState((prevState) => {
      const updatedState = {
        smsDetails: {
          ...prevState.smsDetails,
          [field]: value,
        },
        isModified: true,
        errorMessage:
          field === "phoneNumber" && value.trim()
            ? null
            : prevState.errorMessage,
      };

      if (
        field === "phoneNumber" &&
        value.trim() &&
        prevState.selectedCategory
      ) {
        updatedState.notifications = {
          ...prevState.notifications,
          [prevState.selectedCategory]: {
            ...prevState.notifications[prevState.selectedCategory],
            sms: true,
          },
        };
      }

      return updatedState;
    });
  };

  saveSettings = () => {
    const { user, notificationPreferences } = this.props;
    const { notifications, smsDetails } = this.state;

    // Validation: If any SMS is checked, ensure phone details are filled
    const smsSelected = Object.values(notifications).some(
      (notification) => notification.sms
    );
    if (smsSelected && !smsDetails.phoneNumber) {
      this.setState({
        errorMessage: "Phone number is required for SMS notifications.",
      });
      return;
    }

    const preferences = notificationPreferences?.data?.items || [];
    const updates = preferences.map((preference) => {
      const { id_notification_preference, notification_event } = preference;
      return {
        id_notification_preference,
        id_user: user?.id_user,
        notify_wattmaestro: notifications[notification_event]?.web ? 1 : 0,
        notify_email: notifications[notification_event]?.email ? 1 : 0,
        notify_sms: notifications[notification_event]?.sms ? 1 : 0,
        phone_number: smsDetails.phoneNumber || null,
        updated_at: Math.floor(Date.now() / 1000),
      };
    });

    this.props.updateNotificationPreferences(updates);

    this.setState({ showAlert: true, errorMessage: null, isModified: false });
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 3000);
  };

  addSpaceBetweenCaps = (text) => {
    return text.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/_/g, " ");
  };

  render() {
    const { notifications, smsDetails, showAlert, errorMessage } = this.state;
    const error = this.props.notificationError;

    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });

    return (
      <div>
        <div className="settings-container">
          <div className="settings-title mb-2">Notification Management</div>
          <span className="settings-span mb-3">
            Configure how you want to be notified of an event.
          </span>
          <div className="notification-section mt-3">
            <table className="responsive-table table p-5">
              <thead className="thead-light">
                <tr>
                  <th></th>
                  <th>Notification on WattMaestro</th>
                  <th>Email</th>
                  <th>SMS</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(notifications).map((category) => (
                  <tr key={category}>
                    <td className="text-capitalize">
                      <div className="cat-width">
                        {this.addSpaceBetweenCaps(category)}
                      </div>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={notifications[category].web}
                        onChange={() => this.toggleCheckbox(category, "web")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={notifications[category].email}
                        onChange={() => this.toggleCheckbox(category, "email")}
                      />
                    </td>
                    <td className="cat-width">
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={notifications[category].sms}
                        onChange={() => this.toggleCheckbox(category, "sms")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/*Mobile View Option*/}
          {Object.keys(notifications).map((category) => (
            <div key={category} className="notification-section-mobile mt-3">
              <h3 className="text-capitalize settings-title">
                {this.addSpaceBetweenCaps(category)}
              </h3>
              <div className="notification-options">
                <span className="settings-span ">
                  Notification on WattMaestro
                </span>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={notifications[category].web}
                  onChange={() => this.toggleCheckbox(category, "web")}
                />
              </div>
              <div className="notification-options">
                <span className="settings-span ">Email</span>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={notifications[category].email}
                  onChange={() => this.toggleCheckbox(category, "email")}
                />
              </div>
              <div className="notification-options">
                <span className="settings-span ">SMS</span>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={notifications[category].sms}
                  onChange={() => this.toggleCheckbox(category, "sms")}
                />
              </div>
            </div>
          ))}
          <div className="sms-card">
            <div className="sms-margin-top">
              <div className="settings-title mb-2">SMS Notifications</div>
              <span className="settings-span mb-4">
                If you wish to receive SMS, you must fill the following field:
              </span>
            </div>
            {errorMessage && (
              <div
                className="form-group error-message"
                style={{
                  color: "red",
                  fontFamily: "Roboto Flex",
                  fontWeight: 400,
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="sms-inputs">
              <div className="d-flex flex-wrap align-items-center gap-3">
                <div className="form-group mb-0">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone Number:
                  </label>
                  <input
                    type="tel"
                    className="form-control form-control-sm"
                    id="phoneNumber"
                    value={smsDetails.phoneNumber}
                    onChange={(e) =>
                      this.handleInputChange("phoneNumber", e.target.value)
                    }
                    style={{
                      backgroundColor: "white",
                      width: "230px",
                      borderColor: errorMessage ? "red" : "#C3C3C5",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="save-btn-container mt-3">
            <button
              className="save-btn"
              onClick={this.saveSettings}
              style={{
                backgroundColor: this.state.isModified ? "#25DBA7" : "#C3C3C5",
                cursor: this.state.isModified ? "pointer" : "not-allowed",
              }}
              disabled={!this.state.isModified}
            >
              Save
            </button>
          </div>
        </div>

        {showAlert && (
          <div className="alertDialogNotification">
            <BlackOutlinedErrorAlert
              className={`${
                error === undefined ? "success-dialog" : "error-name-dialog"
              }`}
              style={{
                backgroundColor: error === undefined ? "#84CE66" : "#FF5D5D",
              }}
              severity={error === undefined ? "success" : "error"}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
            >
              <AlertTitle
                style={{
                  fontFamily: "'Roboto Flex', sans-serif",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                {error === undefined
                  ? "Notification Saved"
                  : "Unable to Save Notifications"}
              </AlertTitle>
              {error === undefined
                ? "Notification saved successfully"
                : "Something went wrong."}
            </BlackOutlinedErrorAlert>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    notificationPreferences: state.notificationReducer?.notification,
    notificationError: state.notificationReducer?.error,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchNotificationPreferences,
    updateNotificationPreferences,
  })(SettingsDashboard)
);
