const initialState = {
  routeStart: "",
  topUserPopup: false,
  exportPopup: false,
  exportSessionPopup: false,
  exportCSV: false,
};

export default function screenActivitiesReducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_USER_POPUP": {
      return {
        ...state,
        topUserPopup: true,
        noticationPopup: false,
      };
    }
    case "CLOSE_USER_POPUP": {
      return {
        ...state,
        topUserPopup: false,
        noticationPopup: false,
      };
    }
    case "CLOSE_NOTIFICATION_POPUP": {
      return {
        ...state,
        noticationPopup: false,
        topUserPopup: false,
      };
    }
    case "OPEN_NOTIFICATION_POPUP": {
      return {
        ...state,
        noticationPopup: true,
        topUserPopup: false,
      };
    }
    case "LOCATION_CHANGE": {
      return {
        ...initialState,
        routeStart: action.routeStart,
      };
    }
    case "OPEN_EXPORT_POPUP": {
      return {
        ...state,
        exportPopup: true,
      };
    }
    case "CLOSE_EXPORT_POPUP": {
      return {
        ...state,
        exportPopup: false,
      };
    }
    case "OPEN_SESSION_EXPORT_POPUP": {
      return {
        ...state,
        exportSessionPopup: true,
      };
    }
    case "CLOSE_SESSION_EXPORT_POPUP": {
      return {
        ...state,
        exportSessionPopup: false,
      };
    }
    case "EXPORT_CSV": {
      return {
        ...initialState,
        exportCSV: true,
      };
    }
    case "CLEAR_EXPORT_ACTIONS": {
      return {
        ...state,
        exportCSV: false,
      };
    }
    default: {
      return state;
    }
  }
}
