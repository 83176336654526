import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import EquipmentStatus from "../chart/equipmentStatus";
//import "./styles.css";

import LoaderRoller from "../../components/loader/LoaderRoller";
import TinySpinner from "../../components/loader/TinySpinner";

import InverterChart from "../inverter/inverterChart";
class onlyInverterEquipment extends Component {
  state = {
    formInput: {
      chartList: [],
      options: [],
      series: [],
      chartNameView: [],
      chartUnit: [],
    },
  };
  componentDidMount = () => {};
  componentDidUpdate = () => {};

  render() {
    const formInput = { ...this.state.formInput };
    return (
      <div>
        {" "}
        {this.props.allChartData !== null &&
        this.props.allChartData !== undefined ? (
          <div id="siteOverviewData">
            {this.props.loader ? <TinySpinner /> : ""}
            <div className="margin-bottom container-top mobile-margin">
              <div
                className="col-lg-7 top-chart"
                style={{
                  position: "relative",
                }}
                ref={(el) => (this.energyChartRef = el)}
              >
                <InverterChart
                  allProjectData={this.props.allProjectData}
                  allChartData={this.props.allChartData}
                  inverterChartUnit={this.props.inverterChartUnit}
                  inverterDataSets={this.props.inverterDataSets}
                />
              </div>

              <div className="bacecard-graph-chart top-chart col-lg">
                <EquipmentStatus
                  allProjectData={this.props.allProjectData}
                  equipmentStatusData={this.props.equipmentStatusData}
                  groupId={this.props.groupId}
                />
              </div>
            </div>
          </div>
        ) : (
          <LoaderRoller />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    lastSeenDataResponse: state.listViewReducer?.lastSeenDataResponse,
    noInternet: state.listViewReducer?.noInternet,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(connect(mapStateToProps, {})(onlyInverterEquipment));
