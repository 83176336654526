import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import energyGenerationSubtab from "../../../assets/watt-icons/energy_generation_subtab.svg";
import SiteMode from "../../../assets/watt-icons/site_mode.svg";
import EquipmentStatus from "../../../assets/watt-icons/equipment_status.svg";
import siteModeEdit from "../../../assets/watt-icons/site_mode_edit.svg";
import SolorChart from "./solorChart";
import LoaderRoller from "../../../components/loader/LoaderRoller";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import {
  siteModeUpdate,
  clearSiteModeChange,
} from "../../../actions/list-view";
import "./styles.css";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Homechartfilter from "../../chart/homechartfilter";
import TinySpinner from "../../../components/loader/TinySpinner";
import LastSeenStatus from "../../chart/lastSeenStatus";
import { DialogTitle } from "@material-ui/core";

const options = ["Curtailment", "Monitor only", "Load Balancing"];
class solor extends Component {
  state = {
    formInput: {
      chartViewData: [],
      siteModechange: false,
      siteModeNameValue: "",
      loadBalanceValue: "",
      wattMaestroMode: "",
      sitelabel: "",
      idGroup: "",
      classNameView: "",
      source_device: "",
      alertSiteModeVisible: false,
      monitorGroup: "",
      confirmPopup: false,
      validationError: false,
      validationErrorMsg: "",
      chartSolarId: "",
    },
  };
  handleToChangeSite = () => {
    this.props.clearSiteModeChange();
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = true;
    formInput.siteModeNameValue = "";
    formInput.alertSiteModeVisible = false;
    this.setState({ formInput });
  };

  handleToChangeSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = false;
    this.setState({ formInput });
  };

  handleSiteNameChange = (value) => {
    const formInput = { ...this.state.formInput };
    formInput.siteModeNameValue = value;
    this.setState({ formInput });
  };
  handleLoadBalanceValueChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.loadBalanceValue = input.value;
    this.setState({ formInput });
  };

  handleToClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.lastSeenUpdate = false;
    this.setState({ formInput, alertVisible: false });
  };
  handleFilterData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        ...data,
      },
    }));
  };
  handelValidationPopup = () => {
    const formInput = { ...this.state.formInput };
    formInput.validationError = false;
    this.setState({ formInput });
  };
  openConfirmPopup = () => {
    const formInput = { ...this.state.formInput };
    const nonNumericRegex = /[^0-9]/;
    let nullErrormsg =
      formInput.loadBalanceValue === "" || formInput.loadBalanceValue === null;
    let specialCharmsg =
      nonNumericRegex.test(formInput.loadBalanceValue) ||
      parseFloat(formInput.loadBalanceValue) < 0;

    if (nullErrormsg || specialCharmsg) {
      formInput.validationError = true;
    } else {
      formInput.validationError = false;
      formInput.siteModechange = false;
      formInput.confirmPopup = true;
    }

    formInput.validationErrorMsg = nullErrormsg
      ? "The field “Maximum Power Site should generate (in kW)“ cannot be empty."
      : specialCharmsg
      ? "The field 'Maximum Power Site should generate (in kW)' must only contain numerical values and should not be a negative value."
      : "";

    this.setState({ formInput });
  };

  closeConfirmPopup = () => {
    const formInput = { ...this.state.formInput };
    formInput.confirmPopup = false;
    this.setState({ formInput });
  };
  handleSiteModeSubmitClick = ({ currentTarget: input }) => {
    this.props.clearSiteModeChange();
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    formInput.confirmPopup = false;
    const siteModeRestDate = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    )[0].groupMonitor;

    const dataSolarMonitorMode = siteModeRestDate.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "1"
    );
    const dataSolarCurtailmentMode = siteModeRestDate.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "2"
    );
    const dataSolarLoadBalancingMode = siteModeRestDate.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "3"
    );

    const checkBattryMode =
      dataSolarMonitorMode.length > 0
        ? 1
        : dataSolarCurtailmentMode.length
        ? 2
        : 3;
    const checkBattryModeData = siteModeRestDate.filter(
      (item) =>
        String(item.json_configuration.wattMaestroMode) ===
        checkBattryMode.toString()
    );

    formInput.wattMaestroMode =
      formInput.siteModeNameValue === ""
        ? dataSolarMonitorMode.length > 0
          ? 1
          : dataSolarCurtailmentMode.length > 0
          ? 2
          : dataSolarLoadBalancingMode.length > 0
          ? 3
          : "-"
        : formInput.siteModeNameValue === "Monitor only"
        ? 1
        : formInput.siteModeNameValue === "Curtailment"
        ? 2
        : formInput.siteModeNameValue === "Load Balancing"
        ? 3
        : "-";

    // const sourceDeviceId = this.props.allProjectData?.items
    // .filter((n) => n.id_group === groupId)[0]
    // .physicalDevices.filter(
    //   (m) => m?.id_parent === null
    // )[0].id_physical_device;
    // formInput.sitelabel = siteModeRestDate[0]?.label;
    // formInput.idGroup = siteModeRestDate[0]?.id_group;
    // formInput.source_device = sourceDeviceId;

    formInput.monitorGroup = checkBattryModeData[0]?.id_monitor_rule;
    formInput.alertSiteModeVisible = true;
    formInput.siteModechange = false;
    this.setState({ formInput });

    this.props.siteModeUpdate(formInput, groupId);
  };

  handleToSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.alertSiteModeVisible = false;
    formInput.siteModechange = false;
    this.setState({ formInput, alertVisible: false });
    this.props.clearSiteModeChange();
  };

  render() {
    // let userDetails = this.props.projectMemberDetails?.data?.access;
    // let userValue = userDetails.filter((m) => m.email === this.props.userEmail);
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;

    const KPIdetails = this.props.monitorDetails.filter(
      (p) => p?.deviceProfile?.equipmentLabel === "Solar"
    )[0].deviceProfile;

    const dataKPI = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const monitorData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );

    const accessTypeMapping =
      this.props.projectMemberDetails?.data?.access.filter(
        (p) => p.email === this.props.userEmail
      );
    const AdminRole = this.props.user?.roles?.Administrator !== undefined;
    const userTypeModeAccessible =
      accessTypeMapping.length !== 0
        ? accessTypeMapping[0].type === 50 || accessTypeMapping[0].type === 40
        : false;

    const physicalDevices = dataKPI[0]?.physicalDevices;

    const chartSolarId =
      dataKPI[0]?.latestSummary?.ActivePower?.datasets.length === 1
        ? dataKPI[0]?.latestSummary?.ActivePower?.datasets[0]
        : dataKPI[0]?.latestSummary?.ActivePower?.datasets[
            this.props.dataSetstabNumber
          ];
    formInput.chartSolarId = chartSolarId;
    const chartName = dataKPI[0]?.latestSummary?.ActivePower?.data_category;
    const chartUnit = dataKPI[0]?.latestSummary?.ActivePower?.unit;
    const activePower = dataKPI[0]?.latestSummary?.ActivePower?.sum;
    const activePowerUnit = dataKPI[0]?.latestSummary?.ActivePower?.unit;
    const dailyETotal = dataKPI[0]?.latestSummary?.EDaily?.sum;
    const dailyETotalUnit = dataKPI[0]?.latestSummary?.EDaily?.unit;
    const activeAdjustment =
      dataKPI[0]?.latestSummary?.ActivePowerAdjustment?.sum;
    const activeAdjustmentPercentage =
      dataKPI[0]?.latestSummary?.ActivePowerAdjustmentByPercentage?.sum;
    const activeAdjustmentUnit =
      dataKPI[0]?.latestSummary?.ActivePowerAdjustment?.unit;
    const activeAdjustmentPercentageUnit =
      dataKPI[0]?.latestSummary?.ActivePowerAdjustmentByPercentage?.unit;

    const activeAdjustmentValue =
      activeAdjustment === "null" || activeAdjustment === undefined
        ? activeAdjustmentPercentage
        : activeAdjustment;

    const activeAdjustmentValueUnit =
      activeAdjustmentUnit === "null" || activeAdjustmentUnit === undefined
        ? activeAdjustmentPercentageUnit
        : activeAdjustmentUnit;

    const dataSolarMonitorMode = dataKPI[0].groupMonitor.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "1"
    );
    const dataSolarCurtailmentMode = dataKPI[0].groupMonitor.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "2"
    );
    const dataSolarLoadBalancingMode = dataKPI[0].groupMonitor.filter(
      (item) => String(item.json_configuration.wattMaestroMode) === "3"
    );
    formInput.siteModeNameValue =
      formInput.siteModeNameValue === ""
        ? dataSolarMonitorMode.length > 0
          ? "Monitor only"
          : dataSolarCurtailmentMode.length > 0
          ? "Curtailment"
          : dataSolarLoadBalancingMode.length > 0
          ? "Load Balancing"
          : "-"
        : formInput.siteModeNameValue;

    const siteModeValue =
      dataSolarMonitorMode.length > 0
        ? "Monitor only"
        : dataSolarCurtailmentMode.length > 0
        ? "Curtailment"
        : dataSolarLoadBalancingMode.length > 0
        ? "Load Balancing"
        : "-";

    // const siteModeValue =
    //   String(dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode) ===
    //   "1"
    //     ? "Monitor only"
    //     : String(
    //         dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
    //       ) === "2"
    //     ? "Curtailment"
    //     : String(
    //         dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
    //       ) === "3"
    //     ? "Load Balancing"
    //     : "-";

    const loadBalancingkWValue =
      dataKPI[0]?.groupMonitor?.json_configuration
        ?.configured_set_point_threshold;

    const chartData = this.props.allChartData?.items.filter(
      (m) => m.id_container_data_latest === chartSolarId
    );

    formInput.chartViewData = chartData;
    formInput.equipmentStatusData = dataKPI;

    const getLatestData = (physicalDevices) => {
      if (
        physicalDevices.id_parent !== null &&
        physicalDevices.deviceProfile !== null
      ) {
        return physicalDevices.latestData;
      }
      return null;
    };

    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });

    return (
      <Fragment>
        {" "}
        <div className="filter">
          <LastSeenStatus
            equipmentStatusData={monitorData}
            className="lastSeen"
            filterOptions={this.props.filterOptions}
          />
          <Homechartfilter
            dataSetsArray={this.props.inverterDataSetsInArray}
            chartName={chartName}
            chartUnit={chartUnit}
            handleFilterData={this.handleFilterData}
          />{" "}
        </div>
        {/* confirmation popup */}
        {formInput.confirmPopup && (
          <Dialog
            open={formInput.confirmPopup}
            onClose={this.closeConfirmPopup}
            className="dialogSite"
          >
            <div onClick={this.closeConfirmPopup}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
              />
            </div>
            <FontAwesomeIcon
              style={{
                width: "50px",
                height: "50px",
                color: "#FF8F00",
                alignSelf: "center",
              }}
              icon={faTriangleExclamation}
            />
            <DialogTitle>
              <div
                className="changeSiteModeSolarHeader"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                Load Balancing
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                className="changeSiteModeTitle"
                style={{ maxWidth: "400px", fontWeight: "normal" }}
              >
                Are you sure to set the {this.props.modeName} mode with{" "}
                <span style={{ fontWeight: "bold" }}>
                  {formInput.loadBalanceValue} kW{" "}
                </span>
                max. power generation?
              </div>
              <div className="buttonSiteModeView changeSiteModeTitle">
                <button
                  onClick={this.handleSiteModeSubmitClick}
                  className="submitSiteModeClick"
                >
                  Confirm
                </button>
                <button
                  className="cancelSiteModeClick"
                  onClick={this.closeConfirmPopup}
                >
                  Cancel
                </button>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {formInput.siteModechange &&
          this.props.siteModeChanged === undefined && (
            <Dialog
              open={
                formInput.siteModechange === true &&
                this.props.siteModeChanged === undefined
                  ? true
                  : false
              }
              onClose={this.handleToChangeSiteModeClose}
            >
              <div onClick={this.handleToChangeSiteModeClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                />
              </div>
              <DialogTitle>
                <div className="changeSiteModeSolarHeader">
                  {this.props.modeName} Mode
                </div>
              </DialogTitle>
              <DialogContent>
                <Autocomplete
                  className="dropdown"
                  disableClearable
                  id="dropdown"
                  options={options}
                  getOptionLabel={(option) => option}
                  sx={{ width: "100%" }}
                  onChange={(event, value) => this.handleSiteNameChange(value)}
                  renderInput={(params) => <TextField {...params} />}
                  value={formInput.siteModeNameValue || options[0]}
                />
                <p
                  className="mt-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  For more information about {formInput.siteModeNameValue},
                  refer to the{" "}
                  <a
                    href="https://docs.bace-iot.com/wattmaestro/user-manual"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    User Manual
                  </a>
                  .
                </p>{" "}
                <div>
                  {formInput.siteModeNameValue === "Load Balancing" ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Roboto Flex",
                          fontStyle: "normal",
                        }}
                      >
                        Maximum Power Site should generate (in kW)
                      </p>
                      <div className="changeSiteModeTitle d-flex align-items-center">
                        <input
                          name="name"
                          type="number"
                          min="1"
                          value={formInput?.loadBalanceValue}
                          onChange={this.handleLoadBalanceValueChange}
                          className="siteModbusInput"
                          defaultValue={
                            loadBalancingkWValue === 1000 &&
                            formInput?.loadBalanceValue === ""
                              ? loadBalancingkWValue
                              : formInput.loadBalanceValue
                          }
                          placeholder="kW"
                        />
                        <label
                          style={{
                            fontWeight: "600",
                            fontFamily: "Roboto Flex",
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            marginLeft: "15px",
                          }}
                        >
                          kW
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="buttonSiteModeView changeSiteModeTitle">
                  {" "}
                  <button
                    onClick={
                      formInput.siteModeNameValue === "Load Balancing"
                        ? this.openConfirmPopup
                        : this.handleSiteModeSubmitClick
                    }
                    className="submitSiteModeClick"
                  >
                    Confirm
                  </button>
                  <button
                    className="cancelSiteModeClick"
                    onClick={this.handleToChangeSiteModeClose}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div>
          <div class="KPISolarWidgets">
            <div class="row" style={{ gap: "1%", marginRight: "0px" }}>
              <div class="col kpiSolarView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2">
                      <img
                        src={EquipmentStatus}
                        alt=""
                        title={"Equipment Status"}
                      />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title">
                      <div style={{ display: "flex" }}>
                        {" "}
                        Equipment Status
                        <b
                          className={
                            this.props.monitorDetails[0]?.is_connected === 0
                              ? "OfflineEquipment"
                              : "OnlineEquipment"
                          }
                        >
                          {this.props.monitorDetails[0]?.is_connected === 0
                            ? "Offline"
                            : "Online"}
                        </b>
                      </div>
                      <div className="roboto-flex-uniquifier">
                        {" "}
                        Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiSolarView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2">
                      <img
                        src={energyGenerationSubtab}
                        title={"Grid Mode"}
                        alt=""
                      />
                    </div>
                    <div class="col-md-10 roboto-flex-uniquifier-title">
                      Power Generation
                      <div
                        className="roboto-flex-uniquifier"
                        style={{ height: "18px" }}
                      >
                        Power generation:{" "}
                        {activePower !== null && activePower !== undefined
                          ? activePower + " " + activePowerUnit
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiSolarView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2">
                      <img
                        src={energyGenerationSubtab}
                        title={"Inverter Mode"}
                        alt=""
                      />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title">
                      Daily Energy
                      <div className="roboto-flex-uniquifier">
                        Energy generation:{" "}
                        {dailyETotal !== null && dailyETotal !== undefined
                          ? dailyETotal + " " + dailyETotalUnit
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiSolarView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2">
                      <img src={SiteMode} title={"Site Mode"} alt="" />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title">
                      Active Adjustment
                      <div className="roboto-flex-uniquifier">
                        Power generation limit:{" "}
                        {activeAdjustmentValue !== null &&
                        activeAdjustmentValue !== undefined
                          ? activeAdjustmentValue +
                            " " +
                            activeAdjustmentValueUnit
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiSolarView">
                <div style={{ height: "100%", width: "100%" }}>
                  <div class="row">
                    <div className="col-md-2">
                      <img src={SiteMode} title={"Inverter Mode"} alt="" />
                    </div>
                    <div className="col-md-7 roboto-flex-uniquifier-title">
                      {this.props.modeName} Mode
                      <div className="roboto-flex-uniquifier">
                        {siteModeValue}
                      </div>
                    </div>
                    {AdminRole || userTypeModeAccessible ? (
                      <div
                        className="col-md-3 roboto-flex-uniquifier-title"
                        onClick={this.handleToChangeSite}
                      >
                        <img
                          src={siteModeEdit}
                          alt=""
                          title={"Equipment Status"}
                          className="siteSolarIcon"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view kpi widgets */}
          <div className="mobile-kpi-widgets">
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={EquipmentStatus} alt="" title={"Equipment Status"} />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Equipment Status
                <div className="roboto-flex-uniquifier">
                  Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                </div>
              </div>{" "}
              <b
                className={
                  this.props.monitorDetails[0]?.is_connected === 0
                    ? "OfflineEquipment"
                    : "OnlineEquipment"
                }
              >
                {this.props.monitorDetails[0]?.is_connected === 0
                  ? "Offline"
                  : "Online"}
              </b>
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img
                  src={energyGenerationSubtab}
                  alt=""
                  title={"Power Generation"}
                />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Power Generation
                <div className="roboto-flex-uniquifier">
                  Power generation:{" "}
                  {activePower !== null && activePower !== undefined
                    ? activePower + " " + activePowerUnit
                    : "N/A"}
                </div>
              </div>{" "}
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img
                  src={energyGenerationSubtab}
                  alt=""
                  title={"Daily Energy"}
                />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Daily Energy
                <div className="roboto-flex-uniquifier">
                  Energy generation:{" "}
                  {dailyETotal !== null && dailyETotal !== undefined
                    ? dailyETotal + " " + dailyETotalUnit
                    : "N/A"}
                </div>
              </div>{" "}
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={SiteMode} alt="" title={"Active Adjustment"} />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Active Adjustment
                <div className="roboto-flex-uniquifier">
                  Power generation limit:{" "}
                  {activeAdjustmentValue !== null &&
                  activeAdjustmentValue !== undefined
                    ? activeAdjustmentValue + " " + activeAdjustmentValueUnit
                    : "N/A"}
                </div>
              </div>{" "}
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start justify-content-between">
              <div className="d-flex">
                <div style={{ marginRight: "12px" }}>
                  <img src={SiteMode} alt="" title={"Active Adjustment"} />{" "}
                </div>
                <div className="roboto-flex-uniquifier-title">
                  {" "}
                  {this.props.modeName} Mode
                  <div className="roboto-flex-uniquifier">{siteModeValue}</div>
                </div>
              </div>
              <div
                className="col-md-3 roboto-flex-uniquifier-title"
                onClick={this.handleToChangeSite}
                style={{ marginLeft: "20px" }}
              >
                <img
                  src={siteModeEdit}
                  alt=""
                  title={"Equipment Status"}
                  className="siteSolarIcon"
                />
              </div>
            </div>{" "}
          </div>

          <div className="chartSett">
            <div className="container-top-align">
              <div
                className="bacecard-graph-chart top-chart col-lg-8 box-shadow"
                style={{ margin: "0px 10px 10px 4px" }}
              >
                {this.props.allChartData !== null &&
                this.props.allChartData !== undefined &&
                formInput.chartViewData.length > 0 ? (
                  <div>
                    {this.props.loader ? <TinySpinner /> : ""}
                    <SolorChart chartViewData={formInput.chartViewData} />
                  </div>
                ) : this.props.allChartData !== null &&
                  this.props.allChartData !== undefined ? (
                  <div>No data available</div>
                ) : (
                  <LoaderRoller />
                )}
              </div>

              <div className="chartSetWidthParameter col-lg-3 box-shadow">
                <div className="chartHeading">Inverter</div>

                <div className="row tableHeading">
                  <div className="col-2 col-lg-1">#</div>
                  <div className="col-5 col-lg-6">Label</div>
                  <div className="col-5 col-lg-4">Value</div>
                </div>
                <div className="scrollContentSolar">
                  {physicalDevices.map((physicalDevice, index) => {
                    const latestData = getLatestData(physicalDevice);
                    const equipmentLabel =
                      physicalDevice.deviceProfile?.equipmentLabel;

                    if (
                      equipmentLabel &&
                      equipmentLabel === "Solar" &&
                      latestData
                    ) {
                      const sortedData = latestData
                        .slice()
                        .sort((a, b) =>
                          a.label.trim().localeCompare(b.label.trim())
                        );

                      return sortedData.map((data, dataIndex) => (
                        <div
                          className="row tableDataText"
                          key={dataIndex}
                          style={{ marginLeft: "0px" }}
                        >
                          <div className="col-2 col-lg-1">{dataIndex + 1}</div>
                          <div className="col-5 col-lg-6"> {data.label}</div>
                          <div className="col-5 col-lg-4">
                            {data.value !== undefined
                              ? Number(data.value) % 1 !== 0
                                ? Number(data.value).toFixed(2)
                                : Number(data.value).toFixed(0)
                              : "N/A"}
                            {data.unit}
                          </div>
                        </div>
                      ));
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.siteModeChanged === undefined &&
          this.props.siteModeShowWarning === undefined &&
          formInput.alertSiteModeVisible && (
            <Dialog
              open={
                this.props.siteModeChanged === undefined &&
                formInput.alertSiteModeVisible
                  ? true
                  : false
              }
              className="dialogSite"
            >
              <DialogTitle>
                <div style={{ fontWeight: "400" }}>
                  {this.props.modeName} mode is updating from {siteModeValue} to{" "}
                  {formInput.siteModeNameValue}.
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="changeSiteModeTitle">
                  <LoaderRoller />
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div
          className={
            this.props.siteModeShowWarning !== undefined
              ? "alertCurtailmentErrorSolarDialog"
              : "alertSolarDialog"
          }
          onClick={this.handleToSiteModeClose}
        >
          {(this.props.siteModeChanged !== undefined ||
            this.props.siteModeShowWarning !== undefined) &&
            formInput.alertSiteModeVisible && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.siteModeChanged?.status === 200
                    ? "success-dialog"
                    : this.props.siteModeShowWarning !== undefined
                    ? "error-dialog"
                    : "error-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.siteModeChanged?.status === 200
                      ? "#84CE66"
                      : "#FF5D5D",
                }}
                severity={
                  this.props.siteModeChanged?.status === 200
                    ? "success"
                    : this.props.siteModeShowWarning !== undefined
                    ? "error"
                    : "error"
                }
                onClose={this.handleToSiteModeClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                    textAlign: "justify",
                  }}
                >
                  {this.props.siteModeChanged?.status === 200
                    ? `${this.props.modeName} Mode changed`
                    : `${this.props.modeName} Mode change failed`}
                </AlertTitle>
                {this.props.siteModeChanged?.status === 200 ? (
                  `${this.props.modeName}  Mode has been changed to ` +
                  formInput?.siteModeNameValue
                ) : this.props.siteModeShowWarning !== undefined ? (
                  <div style={{ textAlign: "left" }}>
                    {this.props.siteModeShowWarning}
                  </div>
                ) : (
                  `${this.props.modeName}  failed to changed.`
                )}
              </BlackOutlinedErrorAlert>
            )}
        </div>
        <div className="alertSolarDialog">
          {formInput.validationError && (
            <BlackOutlinedErrorAlert
              className="error-dialog"
              style={{
                backgroundColor: "#FF5D5D",
              }}
              severity="error"
              onClose={this.handelValidationPopup}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
            >
              <AlertTitle
                style={{
                  fontFamily: "'Roboto Flex', sans-serif",
                  fontWeight: "600",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                {this.props.modeName} Mode change Failed
              </AlertTitle>
              {formInput.validationErrorMsg}
            </BlackOutlinedErrorAlert>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    siteModeChanged: state.listViewReducer?.siteModeChanged,
    siteModeShowWarning: state.listViewReducer.siteModeShowWarning,
    loader: state.listViewReducer?.loader,
    userEmail: state.userReducer?.user?.email,
    user: state.userReducer,
  };
}

export default withRouter(
  connect(mapStateToProps, { siteModeUpdate, clearSiteModeChange })(solor)
);
