import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import Homechartfilter from "../../chart/homechartfilter";
import TinySpinner from "../../../components/loader/TinySpinner";
import LastSeenStatus from "../../chart/lastSeenStatus";
import Batterychart from "../../battery/batteryChart";
import EquipmentStatus from "../../../assets/watt-icons/equipment_status.svg";
import siteModeEdit from "../../../assets/watt-icons/site_mode_edit.svg";
import siteMode from "../../../assets/watt-icons/site_mode.svg";
import battery0Plus from "../../../assets/battery/battery0-5%.png";
import battery6Plus from "../../../assets/battery/battery6-20%.png";
import battery20Plus from "../../../assets/battery/battery21-40%.png";
import battery40Plus from "../../../assets/battery/battery41-60%.png";
import battery60Plus from "../../../assets/battery/battery61-80%.png";
import battery80Plus from "../../../assets/battery/battery81-94%.png";
import battery95Plus from "../../../assets/battery/battery95-100%.png";
import battery100 from "../../../assets/battery/battery100%.png";
import {
  getAllProject,
  getAllChartData,
  clearNoInternet,
  siteModeUpdate,
  clearSiteModeChange,
  siteModeBatteryUpdate,
} from "../../../actions/list-view";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Autocomplete, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import "./styles.css";
import LoaderRoller from "../../../components/loader/LoaderRoller";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

class battery extends Component {
  state = {
    formInput: {
      dataBatteryMonitorMode: [],
      dataBatteryOptimizationMode: [],
      fromDate: "",
      toDate: "",
      chartViewData: [],
      dataSetsArray: [],
      siteModechange: false,
      alertSiteModeVisible: false,
      siteModeNameValue: "",
      loadBalanceValue: "",
      batteryModeValue: "",
    },
  };

  handleFilterData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        ...data,
      },
    }));
  };

  handleToChangeSite = () => {
    //   this.props.clearSiteModeChange();
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = true;
    formInput.alertSiteModeVisible = false;
    this.setState({ formInput });
  };

  handleToSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.alertSiteModeVisible = false;
    formInput.siteModechange = false;
    this.setState({ formInput, alertVisible: false });
    this.props.clearSiteModeChange();
  };
  handleSiteNameChange = (value) => {
    //const selectedOption = e.target.getAttribute("value");
    const formInput = { ...this.state.formInput };
    // formInput[input.name] = input.value;
    //   formInput.siteModeNameValue = selectedOption;
    formInput.siteModeNameValue = value;
    formInput.loadBalanceValue = "";
    this.setState({ formInput });
  };

  handleToBatteryChangeSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = false;
    this.setState({ formInput });
  };

  handleSiteModeSubmitClick = () => {
    this.props.clearSiteModeChange();
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const siteModeRestDate = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    )[0].groupMonitor;

    const dataBatteryMonitorMode = siteModeRestDate.filter(
      (item) => String(item.json_configuration.batteryMode) === "1"
    );
    const dataBatteryOptimizationMode = siteModeRestDate.filter(
      (item) => String(item.json_configuration.batteryMode) === "2"
    );

    formInput.batteryModeValue =
      formInput.siteModeNameValue === ""
        ? dataBatteryMonitorMode.length > 0
          ? 1
          : dataBatteryOptimizationMode.length > 0
          ? 2
          : "-"
        : formInput.siteModeNameValue === "Monitor only"
        ? 1
        : formInput.siteModeNameValue === "Optimization"
        ? 2
        : "-";
    const checkBatteryMode = dataBatteryMonitorMode.length > 0 ? 1 : 2;
    const checkBatteryModeData = siteModeRestDate.filter(
      (item) =>
        String(item.json_configuration.batteryMode) ===
        checkBatteryMode.toString()
    );
    // const sourceDeviceId = this.props.allProjectData?.items
    // .filter((n) => n.id_group === groupId)[0]
    // .physicalDevices.filter(
    //   (m) => m?.id_parent === null
    // )[0].id_physical_device;
    // formInput.source_device = sourceDeviceId;
    // formInput.sitelabel = siteModeRestDate[0]?.label;
    // formInput.idGroup = siteModeRestDate[0]?.id_group;

    formInput.monitorGroup = checkBatteryModeData[0]?.id_monitor_rule;
    formInput.alertSiteModeVisible = true;
    formInput.siteModechange = false;
    this.setState({ formInput });
    this.props.siteModeBatteryUpdate(formInput, groupId);
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    let dataView = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const dataBatteryMonitorMode = dataView[0].groupMonitor.filter(
      (item) => String(item.json_configuration.batteryMode) === "1"
    );
    const dataBatteryOptimizationMode = dataView[0].groupMonitor.filter(
      (item) => String(item.json_configuration.batteryMode) === "2"
    );
    formInput.dataBatteryMonitorMode = dataBatteryMonitorMode;
    formInput.dataBatteryOptimizationMode = dataBatteryOptimizationMode;
    let dataSet = [];
    let energyData = [];
    let combinedDatasets = [];
    let dataviewList = dataView[0].latestSummary;
    var date = new Date();

    let start = date.setHours(0, 0, 0, 0);
    let startdate = start / 1000;

    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    let enddate = endDateMLSec / 1000;
    const currentTime = new Date().getTime();
    formInput.fromDate = startdate;
    formInput.toDate = Math.round(currentTime / 1000);
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": startdate,
      "filter[timestamp_seconds][lte]": enddate,
    };

    Object.keys(dataviewList).forEach((key) => {
      const dataCategory = dataviewList[key].data_category;

      if (dataCategory === "BatteryStateOfCharge") {
        const datasetName = dataviewList[key].datasets;
        combinedDatasets = combinedDatasets.concat(datasetName);
        formInput.batteryDataSets = datasetName;
      }
      if (dataCategory === "EnergyPrice") {
        energyData.push(dataviewList[key]);
        const datasetName = dataviewList[key].datasets;
        formInput.energyDataSets = datasetName;
        combinedDatasets = combinedDatasets.concat(datasetName);
      }
    });

    combinedDatasets.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });

    formInput.combinedDatasets = combinedDatasets;

    dataSet.forEach((m, index) => {
      formInput.dataSetsArray[index] = m[0];
    });
    // dataName.forEach((p, index) => {
    //   formInput.chartName[index] = p;
    // });

    // dataUnit.forEach((n, index) => {
    //   formInput.chartUnit[index] = n;
    // });
    formInput.energyData = energyData;

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");

    formInput.filterOptions = params;
    this.setState({ formInput });
    //  this.props.getAllChartData(params);
  };

  render() {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const KPIdetails = this.props.monitorDetails.filter(
      (p) => p?.deviceProfile?.equipmentLabel === "Battery"
    )[0].deviceProfile;
    const options = ["Monitor only", "Optimization"];
    const dataKPI = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const accessTypeMapping =
      this.props.projectMemberDetails?.data?.access.filter(
        (p) => p.email === this.props.userEmail
      );

    const AdminRole = this.props.user?.roles?.Administrator !== undefined;
    const userTypeModeAccessible =
      accessTypeMapping.length !== 0
        ? accessTypeMapping[0].type === 50 || accessTypeMapping[0].type === 40
        : false;

    const batteryStateOfCharge =
      dataKPI[0]?.latestSummary?.BatteryStateOfCharge?.average;

    const batteryTarget = dataKPI[0]?.latestSummary?.BatteryTarget?.average;

    const batteryStateOfImage =
      batteryStateOfCharge === 100 || batteryStateOfCharge > 100
        ? battery100
        : batteryStateOfCharge > 94 && batteryStateOfCharge < 100
        ? battery95Plus
        : batteryStateOfCharge > 80 && batteryStateOfCharge < 95
        ? battery80Plus
        : batteryStateOfCharge > 60 && batteryStateOfCharge < 81
        ? battery60Plus
        : batteryStateOfCharge > 40 && batteryStateOfCharge < 61
        ? battery40Plus
        : batteryStateOfCharge > 21 && batteryStateOfCharge < 41
        ? battery20Plus
        : batteryStateOfCharge > 5 && batteryStateOfCharge < 21
        ? battery6Plus
        : battery0Plus;

    const batteryTargetOfImage =
      batteryStateOfCharge === 100 || batteryStateOfCharge > 100
        ? battery100
        : batteryStateOfCharge > 94 && batteryStateOfCharge < 100
        ? battery95Plus
        : batteryTarget > 80 && batteryTarget < 95
        ? battery80Plus
        : batteryTarget > 60 && batteryTarget < 81
        ? battery60Plus
        : batteryTarget > 40 && batteryTarget < 61
        ? battery40Plus
        : batteryTarget > 21 && batteryTarget < 41
        ? battery20Plus
        : batteryTarget > 5 && batteryTarget < 21
        ? battery6Plus
        : battery0Plus;

    const physicalDevices = dataKPI[0]?.physicalDevices;
    const chartEnergyId = dataKPI[0]?.latestSummary?.EnergyPrice?.datasets[0];
    const chartData = this.props.allChartData?.items.filter(
      (m) => m.id_container_data_latest === chartEnergyId
    );
    formInput.chartViewData = chartData;
    var energyData = [];
    let dataSet = [];
    let dataName = [];
    let dataUnit = [];
    Object.keys(dataKPI[0]?.latestSummary).forEach((key) => {
      const dataCategory = dataKPI[0]?.latestSummary[key].data_category;
      if (
        dataCategory === "BatteryStateOfCharge" ||
        dataCategory === "EnergyPrice"
      ) {
        energyData.push(dataKPI[0]?.latestSummary[key]);
        //for filter
        const datasetName = dataKPI[0]?.latestSummary[key].datasets;
        const chartName = dataKPI[0]?.latestSummary[key].data_category;
        const chatUnit = dataKPI[0]?.latestSummary[key].unit;
        dataSet.push(datasetName);
        dataName.push(chartName);
        dataUnit.push(chatUnit);
      }
    });

    dataSet.forEach((m, index) => {
      formInput.dataSetsArray[index] = m[0];
    });
    const chartName =
      dataKPI[0]?.latestSummary?.BatteryStateOfCharge?.data_category;
    const chartUnit = dataKPI[0]?.latestSummary?.BatteryStateOfCharge?.unit;

    const dataBatteryMonitorMode = dataKPI[0].groupMonitor.filter(
      (item) => String(item.json_configuration.batteryMode) === "1"
    );
    const dataBatteryOptimizationMode = dataKPI[0].groupMonitor.filter(
      (item) => String(item.json_configuration.batteryMode) === "2"
    );

    formInput.siteModeNameValue =
      formInput.siteModeNameValue === ""
        ? dataBatteryMonitorMode.length > 0
          ? "Monitor only"
          : dataBatteryOptimizationMode.length > 0
          ? "Optimization"
          : "-"
        : formInput.siteModeNameValue;

    const siteModeValue =
      dataBatteryMonitorMode.length > 0
        ? "Monitor only"
        : dataBatteryOptimizationMode.length > 0
        ? "Optimization"
        : "-";

    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });
    const getLatestData = (physicalDevices) => {
      if (
        physicalDevices.id_parent !== null &&
        physicalDevices.deviceProfile !== null
      ) {
        return physicalDevices.latestData;
      }
      return null;
    };
    return (
      <Fragment>
        <div className="filter">
          <LastSeenStatus
            equipmentStatusData={dataKPI}
            className="lastSeen"
            filterOptions={this.props.filterOptions}
          />
          <Homechartfilter
            dataSetsArray={formInput.dataSetsArray}
            chartName={chartName}
            chartUnit={chartUnit}
            handleFilterData={this.handleFilterData}
          />
        </div>
        {formInput.siteModechange &&
          this.props.siteModeBatteryChanged === undefined && (
            <Dialog
              open={
                formInput.siteModechange === true &&
                this.props.siteModeBatteryChanged === undefined
                  ? true
                  : false
              }
              onClose={this.handleToBatteryChangeSiteModeClose}
              className="dialogSite"
            >
              <div onClick={this.handleToBatteryChangeSiteModeClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToBatteryChangeSiteModeClose}
                />
              </div>
              <DialogTitle>
                <div className="changeSiteModeHeader">Battery Mode</div>
              </DialogTitle>
              <DialogContent>
                <Autocomplete
                  className="dropdown"
                  disableClearable
                  id="dropdown"
                  options={options}
                  getOptionLabel={(option) => option}
                  sx={{ width: "100%" }}
                  onChange={(event, value) => this.handleSiteNameChange(value)}
                  renderInput={(params) => <TextField {...params} />}
                  value={formInput.siteModeNameValue || options[0]}
                />
                <p
                  className="mt-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  For more information about {formInput.siteModeNameValue},
                  refer to the{" "}
                  <a
                    href="https://docs.bace-iot.com/wattmaestro/user-manual"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    User Manual
                  </a>
                  .
                </p>{" "}
                <div className="buttonSiteModeView changeSiteModeTitle">
                  {" "}
                  <button
                    onClick={this.handleSiteModeSubmitClick}
                    className="submitSiteModeClick"
                  >
                    Confirm
                  </button>{" "}
                  <button
                    className="cancelSiteModeClick"
                    onClick={this.handleToBatteryChangeSiteModeClose}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div>
          <div class="KPIBatteryWidgets">
            <div class="row" style={{ gap: "1%", marginRight: "0px" }}>
              <div class="col kpiBatteryView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img
                        src={EquipmentStatus}
                        alt=""
                        title={"Equipment Status"}
                      />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      <div style={{ display: "flex" }}>
                        {" "}
                        Equipment Status{" "}
                        <b
                          className={
                            this.props.monitorDetails[0]?.is_connected === 0
                              ? "OfflineEquipment"
                              : "OnlineEquipment"
                          }
                        >
                          {this.props.monitorDetails[0]?.is_connected === 0
                            ? "Offline"
                            : "Online"}
                        </b>
                      </div>
                      <div className="roboto-flex-uniquifier">
                        {" "}
                        Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiBatteryView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-3 headerNameText">
                      <img
                        src={batteryStateOfImage}
                        title={"Grid Mode"}
                        alt=""
                        style={{ width: "45px" }}
                      />
                    </div>
                    <div class="col-md-9 roboto-flex-uniquifier-title headerNameText">
                      Energy Storage
                      <div
                        className="roboto-flex-uniquifier"
                        style={{ height: "18px" }}
                      >
                        {batteryStateOfCharge} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiBatteryView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-3 headerNameText">
                      <img
                        src={batteryTargetOfImage}
                        title={"Site Mode"}
                        alt=""
                        style={{ width: "45px" }}
                      />
                    </div>
                    <div className="col-md-9 roboto-flex-uniquifier-title headerNameText">
                      Energy Target
                      <div className="roboto-flex-uniquifier">
                        {batteryTarget} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiBatteryView">
                <div style={{ height: "100%", width: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img src={siteMode} title={"Site Mode"} alt="" />
                    </div>
                    <div className="col-md-7 roboto-flex-uniquifier-title headerNameText">
                      Battery Mode
                      <div className="roboto-flex-uniquifier">
                        {siteModeValue}{" "}
                      </div>
                    </div>
                    {AdminRole || userTypeModeAccessible ? (
                      <div
                        className="col-md-3 roboto-flex-uniquifier-title"
                        onClick={this.handleToChangeSite}
                      >
                        <img src={siteModeEdit} title={"Site Mode"} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view kpi widgets */}
          <div className="mobile-kpi-widgets">
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={EquipmentStatus} alt="" title={"Equipment Status"} />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Equipment Status{" "}
                <b
                  className={
                    this.props.monitorDetails[0]?.is_connected === 0
                      ? "OfflineEquipment"
                      : "OnlineEquipment"
                  }
                >
                  {this.props.monitorDetails[0]?.is_connected === 0
                    ? "Offline"
                    : "Online"}
                </b>
                <div className="roboto-flex-uniquifier">
                  Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                </div>
              </div>{" "}
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img
                  src={batteryStateOfImage}
                  title={"Grid Mode"}
                  alt=""
                  style={{ width: "45px" }}
                />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Energy Storage{" "}
                <div className="roboto-flex-uniquifier">
                  {batteryStateOfCharge} %
                </div>
              </div>{" "}
            </div>

            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img
                  src={batteryTargetOfImage}
                  title={"Grid Mode"}
                  alt=""
                  style={{ width: "45px" }}
                />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Energy Target
                <div className="roboto-flex-uniquifier">{batteryTarget} % </div>
              </div>{" "}
            </div>

            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div className="d-flex">
                <div style={{ marginRight: "12px" }}>
                  <img src={siteMode} alt="" title={"Active Adjustment"} />{" "}
                </div>
                <div className="roboto-flex-uniquifier-title">
                  {" "}
                  Equipment Mode
                  <div className="roboto-flex-uniquifier">
                    {" "}
                    {formInput.dataBatteryMonitorMode.length > 0
                      ? "Monitor Only"
                      : formInput.dataBatteryOptimizationMode.length > 0
                      ? "Optimization"
                      : "N/A"}{" "}
                  </div>
                </div>
              </div>
              <div
                className="col-md-3 roboto-flex-uniquifier-title"
                onClick={this.handleToChangeSite}
                style={{ marginLeft: "20px" }}
              >
                <img
                  src={siteModeEdit}
                  alt=""
                  title={"Equipment Status"}
                  className="siteSolarIcon"
                />
              </div>
            </div>
          </div>

          <div className="chartSett">
            <div className="container-top-align">
              <div
                className="bacecard-graph-chart top-chart col-lg-8 box-shadow"
                style={{ margin: "0px 10px 10px 4px" }}
              >
                {this.props.allChartData !== null &&
                this.props.allChartData !== undefined &&
                formInput.batteryDataSets !== undefined ? (
                  <div>
                    {this.props.loader ? <TinySpinner /> : ""}
                    <Batterychart
                      allProjectData={this.props.allProjectData}
                      allChartData={this.props.allChartData}
                      batteryDataSets={formInput.batteryDataSets}
                      energyDataSets={formInput.energyDataSets}
                      dataBatteryMonitorMode={formInput.dataBatteryMonitorMode}
                      dataBatteryOptimizationMode={
                        formInput.dataBatteryOptimizationMode
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <LoaderRoller />
                  </div>
                )}
              </div>

              <div className="chartSetWidthParameter col-md-3 box-shadow">
                <div className="chartHeading">Battery</div>

                <div className="row tableHeading">
                  <div className="col-2 col-lg-1">#</div>
                  <div className="col-5 col-lg-6">Label</div>
                  <div className="col-5 col-lg-4">Value</div>
                </div>
                <div className="scrollContentBattery">
                  {physicalDevices.map((physicalDevice) => {
                    const latestData = getLatestData(physicalDevice);
                    const equipmentLabel =
                      physicalDevice.deviceProfile?.equipmentLabel;

                    if (
                      equipmentLabel &&
                      equipmentLabel === "Battery" &&
                      latestData
                    ) {
                      const sortedData = latestData
                        .slice()
                        .sort((a, b) =>
                          a.label.trim().localeCompare(b.label.trim())
                        );

                      return sortedData.map((data, dataIndex) => (
                        <div
                          className="row tableDataText"
                          key={dataIndex}
                          style={{ marginLeft: "0px" }}
                        >
                          <div className="fullwidth col-2 col-lg-1">
                            {dataIndex + 1}
                          </div>
                          <div className="fullwidth col-5 col-lg-6">
                            {data.label}
                          </div>
                          <div className="fullwidth col-5 col-lg-4">
                            {data.value !== undefined
                              ? Number(data.value) % 1 !== 0
                                ? Number(data.value).toFixed(2)
                                : Number(data.value).toFixed(0)
                              : "N/A"}
                            {data.unit}
                          </div>
                        </div>
                      ));
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.siteModeBatteryChanged === undefined &&
          this.props.batteryModeShowWarning === undefined &&
          formInput.alertSiteModeVisible && (
            <Dialog
              open={
                this.props.siteModeBatteryChanged === undefined &&
                formInput.alertSiteModeVisible
                  ? true
                  : false
              }
              className="dialogSite"
            >
              <DialogTitle>
                <div style={{ fontWeight: "400" }}>
                  Site mode is updating from {siteModeValue} to{" "}
                  {formInput.siteModeNameValue}.
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="changeSiteModeTitle">
                  <LoaderRoller />
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div
          className={
            this.props.batteryModeShowWarning !== undefined
              ? "alertBatteryWarning"
              : "alertBatteryDialog"
          }
          onClick={this.handleToSiteModeClose}
        >
          {(this.props.siteModeBatteryChanged !== undefined ||
            this.props.batteryModeShowWarning !== undefined) &&
            formInput.alertSiteModeVisible && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.siteModeBatteryChanged?.status === 200
                    ? "success-name-dialog"
                    : this.props.batteryModeShowWarning !== undefined
                    ? "error-dialog"
                    : "error-name-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.siteModeBatteryChanged?.status === 200
                      ? "#84CE66"
                      : "#FF5D5D",
                }}
                severity={
                  this.props.siteModeBatteryChanged?.status === 200
                    ? "success"
                    : this.props.batteryModeShowWarning !== undefined
                    ? "error"
                    : "error"
                }
                onClose={this.handleToSiteModeClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {this.props.siteModeBatteryChanged?.status === 200
                    ? "Battery Mode changed"
                    : "Battery Mode change failed"}
                </AlertTitle>

                {this.props.siteModeBatteryChanged?.status === 200 ? (
                  `Battery Mode has been changed to ` +
                  formInput?.siteModeNameValue +
                  `. `
                ) : this.props.batteryModeShowWarning !== undefined ? (
                  <div style={{ textAlign: "left" }}>
                    {this.props.batteryModeShowWarning}
                  </div>
                ) : (
                  `Battery mode  failed to changed.`
                )}
              </BlackOutlinedErrorAlert>
            )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    siteModeBatteryChanged: state.listViewReducer?.siteModeBatteryChanged,
    batteryModeShowWarning: state.listViewReducer.batteryModeShowWarning,
    userEmail: state.userReducer?.user?.email,
    loader: state.listViewReducer?.loader,
    lastSeenDataResponse: state.listViewReducer?.lastSeenDataResponse,
    noInternet: state.listViewRedcer?.noInternet,
    user: state.userReducer,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    siteModeUpdate,
    clearSiteModeChange,
    getAllChartData,
    getAllProject,
    clearNoInternet,
    siteModeBatteryUpdate,
  })(battery)
);
