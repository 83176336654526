import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Chart from "react-apexcharts";
import EquipmentStatus from "../chart/equipmentStatus";
//import "./styles.css";

import LoaderRoller from "../../components/loader/LoaderRoller";
import TinySpinner from "../../components/loader/TinySpinner";
import WeatherStatus from "../chart/weatherStatus";
import SolarIrradiance from "../chart/solarIrradiance";
import InverterChart from "../inverter/inverterChart";
import EnergyChartView from "../chart/EnergyChartView";
import Homechart from "../chart/homechart";

class inverterAndEneryMeterEquipment extends Component {
  state = {
    formInput: {
      chartList: [],
      options: [],
      series: [],
      chartNameView: [],
      chartUnit: [],
    },
  };
  componentDidMount = () => {};
  componentDidUpdate = () => {};

  render() {
    const formInput = { ...this.state.formInput };
    return (
      <div>
        {" "}
        {this.props.allChartData !== null &&
        this.props.allChartData !== undefined ? (
          <div id="siteOverviewData">
            {this.props.loader ? <TinySpinner /> : ""}
            <div className="margin-bottom container-top mobile-margin">
              <div
                className="col-lg-7 top-chart"
                style={{
                  position: "relative",
                }}
                ref={(el) => (this.energyChartRef = el)}
              >
                <EnergyChartView data={this.props.data} />
              </div>
              <div className="bacecard-graph-chart top-chart col-lg">
                <EquipmentStatus
                  allProjectData={this.props.allProjectData}
                  equipmentStatusData={this.props.equipmentStatusData}
                  groupId={this.props.groupId}
                />
              </div>
            </div>{" "}
            <div ref={this.powerChartRef}>
              <Homechart
                allChartData={this.props.allChartData}
                dataSetsArray={this.props.dataSetsArray}
                chartName={this.props.chartName}
                chartUnit={this.props.chartUnit}
                getSeriesData={this.props.getSeriesData}
                chartdata={this.props.chartdata}
              />
            </div>
            <div className="margin-bottom container-top mobile-margin">
              <div
                className="col-lg-7 top-chart"
                style={{
                  position: "relative",
                }}
                ref={(el) => (this.energyChartRef = el)}
              >
                <InverterChart
                  allProjectData={this.props.allProjectData}
                  allChartData={this.props.allChartData}
                  inverterChartUnit={this.props.inverterChartUnit}
                  inverterDataSets={this.props.inverterDataSets}
                />
              </div>
            </div>
          </div>
        ) : (
          <LoaderRoller />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    lastSeenDataResponse: state.listViewReducer?.lastSeenDataResponse,
    noInternet: state.listViewReducer?.noInternet,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(
  connect(mapStateToProps, {})(inverterAndEneryMeterEquipment)
);
