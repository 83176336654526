import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faBookOpen, faGear } from "@fortawesome/pro-regular-svg-icons";
import { logoutUser } from "../../../actions/user";
import {
  openUserPopup,
  closeUserPopup,
} from "../../../actions/screen-activities";
import "../style.css";

class WattMaestroUser extends Component {
  state = {
    formInput: {
      buildName: "",
      buildVersion: "",
      showSettings: "",
    },
  };

  openSettings = () => {
    const idUser = this.props.user?.id_user;
    this.props.history.push(`/settings/${idUser}`);
  };

  componentDidMount = async () => {
    const formInput = { ...this.state.formInput };
    const abc = await fetch("/tags.json");
    const buildVersion = await abc.json();
    formInput.buildName = buildVersion.tags[0];
    formInput.buildVersion = buildVersion.tags[1];
    this.setState({ formInput });
  };

  render() {
    return (
      <Fragment>
        <div
          className={`${
            this.props.topPopup ? "d-flex" : "d-none"
          } justify-content-end`}
        >
          <div className="user-watt-dialog">
            <div className="col">
              <div className="divUserPopup">
                <div className="buildUserWattName">
                  <div className="versionEmailViewName">
                    {this.props.userEmail}
                  </div>
                </div>
                <hr className="emailHR" />{" "}
                <div className="functionIcon">
                  <div
                    className="versionUserViewName"
                    onClick={this.openSettings}
                  >
                    <FontAwesomeIcon icon={faGear} className="iconView" />
                    <div className="userManual">Settings</div>
                  </div>
                  <div
                    className="versionUserViewName"
                    onClick={() =>
                      window.open(
                        "https://docs.bace-iot.com/wattmaestro/user-manual",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    <FontAwesomeIcon icon={faBookOpen} className="iconView" />
                    <div className="userManual"> User Manual</div>
                  </div>
                </div>
                <hr className="emailHR" />
                <div className="functionIcon">
                  <button
                    className="logout-btn  versionUserViewName"
                    onClick={this.props.logoutUser}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="iconView" />
                    &nbsp; Log out &nbsp; &nbsp;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
    logoutUser,
  })(WattMaestroUser)
);
