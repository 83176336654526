import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import Temperature from "../../../assets/watt-icons/temperature.svg";
import EquipmentStatus from "../../../assets/watt-icons/equipment_status.svg";
import LoaderRoller from "../../../components/loader/LoaderRoller";
import HeatChart from "./heatChart";
import Homechartfilter from "../../chart/homechartfilter";
import TinySpinner from "../../../components/loader/TinySpinner";
import LastSeenStatus from "../../chart/lastSeenStatus";

class heat extends Component {
  state = {
    formInput: {
      chartViewData: [],
    },
  };
  handleFilterData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        ...data,
      },
    }));
  };
  render() {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const KPIdetails = this.props.monitorDetails.filter(
      (p) => p?.deviceProfile?.equipmentLabel === "Heat Pump"
    )[0].deviceProfile;

    const dataKPI = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const monitorData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const chartHeatId =
      dataKPI[0]?.latestSummary?.SplitEnergyMeterPowerNegative?.datasets[0];
    const chartName =
      dataKPI[0]?.latestSummary?.SplitEnergyMeterPowerNegative?.data_category;
    const chartUnit =
      dataKPI[0]?.latestSummary?.SplitEnergyMeterPowerNegative?.unit;

    const physicalDevices = dataKPI[0]?.physicalDevices;

    const returnTemp = dataKPI[0]?.groupDataCategories.filter(
      (h) => h.data_category === "ReturnTemperatureHeatPump1"
    )[0].sum;
    const flowTemp = dataKPI[0]?.groupDataCategories.filter(
      (h) => h.data_category === "FlowTemperatureHeatPump1"
    )[0].sum;
    const outsideTemp = dataKPI[0]?.groupDataCategories.filter(
      (h) => h.data_category === "OutsideTemperature"
    )[0].sum;
    const ActualFlowTemp = dataKPI[0]?.groupDataCategories.filter(
      (h) => h.data_category === "ActualFlowTemperature"
    )[0].sum;

    const chartData = this.props.allChartData?.items.filter(
      (m) => m.id_container_data_latest === chartHeatId
    );
    formInput.chartViewData = chartData;

    const getLatestData = (physicalDevices) => {
      if (
        physicalDevices.id_parent !== null &&
        physicalDevices.deviceProfile !== null
      ) {
        return physicalDevices.latestData;
      }
      return null;
    };

    return (
      <Fragment>
        <div className="filter">
          <LastSeenStatus
            equipmentStatusData={monitorData}
            className="lastSeen"
            filterOptions={this.props.filterOptions}
          />
          <Homechartfilter
            dataSetsArray={chartHeatId}
            chartName={chartName}
            chartUnit={chartUnit}
            handleFilterData={this.handleFilterData}
          />
        </div>
        <div>
          <div class="KPIHeatWidgets">
            <div class="row" style={{ gap: "1%", marginRight: "0px" }}>
              <div class="col kpiHeatView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img
                        src={EquipmentStatus}
                        alt=""
                        title={"Equipment Status"}
                      />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      <div style={{ display: "flex" }}>
                        {" "}
                        Equipment Status
                        <b
                          className={
                            this.props.monitorDetails[0]?.is_connected === 0
                              ? "OfflineEquipment"
                              : "OnlineEquipment"
                          }
                        >
                          {this.props.monitorDetails[0]?.is_connected === 0
                            ? "Offline"
                            : "Online"}
                        </b>
                      </div>
                      <div className="roboto-flex-uniquifier">
                        {" "}
                        Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiHeatView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img src={Temperature} title={"Grid Mode"} alt="" />
                    </div>
                    <div class="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      Return Temperature
                      <div
                        className="roboto-flex-uniquifier"
                        style={{ height: "18px" }}
                      >
                        {returnTemp} °C
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiHeatView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img src={Temperature} title={"Site Mode"} alt="" />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      Flow Temperature
                      <div className="roboto-flex-uniquifier">
                        {flowTemp} °C
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiHeatView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img src={Temperature} title={"Site Mode"} alt="" />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      Outside Temperature
                      <div className="roboto-flex-uniquifier">
                        {outsideTemp} °C
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col kpiHeatView">
                <div style={{ height: "100%" }}>
                  <div class="row">
                    <div className="col-md-2 headerNameText">
                      <img src={Temperature} title={"Site Mode"} alt="" />
                    </div>
                    <div className="col-md-10 roboto-flex-uniquifier-title headerNameText">
                      Actual Flow Temperture
                      <div className="roboto-flex-uniquifier">
                        {ActualFlowTemp} °C
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view kpi widgets */}
          <div className="mobile-kpi-widgets">
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={EquipmentStatus} alt="" title={"Equipment Status"} />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Equipment Status
                <div className="roboto-flex-uniquifier">
                  Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                </div>
              </div>{" "}
              <b
                className={
                  this.props.monitorDetails[0]?.is_connected === 0
                    ? "OfflineEquipment"
                    : "OnlineEquipment"
                }
              >
                {this.props.monitorDetails[0]?.is_connected === 0
                  ? "Offline"
                  : "Online"}
              </b>
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={Temperature} title={"Grid Mode"} alt="" />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Return Temperature
                <div className="roboto-flex-uniquifier"> {returnTemp} °C</div>
              </div>{" "}
            </div>
            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={Temperature} title={"Grid Mode"} alt="" />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Flow Temperature
                <div className="roboto-flex-uniquifier"> {flowTemp} °C</div>
              </div>{" "}
            </div>

            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={Temperature} title={"Grid Mode"} alt="" />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Outside Temperature
                <div className="roboto-flex-uniquifier"> {outsideTemp} °C</div>
              </div>{" "}
            </div>

            <div className="kpiView mb-2-mobile d-flex align-items-start">
              <div style={{ marginRight: "12px" }}>
                <img src={Temperature} title={"Grid Mode"} alt="" />{" "}
              </div>
              <div className="roboto-flex-uniquifier-title">
                {" "}
                Actual Flow Temperature
                <div className="roboto-flex-uniquifier">
                  {ActualFlowTemp} °C
                </div>
              </div>{" "}
            </div>
          </div>

          <div className="chartSett">
            <div className="container-top-align">
              <div
                className="bacecard-graph-chart top-chart col-lg-8 box-shadow"
                style={{ margin: "0px 10px 10px 4px" }}
              >
                {this.props.allChartData !== null &&
                this.props.allChartData !== undefined &&
                formInput.chartViewData.length > 0 ? (
                  <div>
                    {this.props.loader ? <TinySpinner /> : ""}
                    <HeatChart chartViewData={formInput.chartViewData} />
                  </div>
                ) : this.props.allChartData !== null &&
                  this.props.allChartData !== undefined ? (
                  <div>No data available</div>
                ) : (
                  <LoaderRoller />
                )}
              </div>

              <div className="chartSetWidthParameter col-lg-3 box-shadow">
                <div className="chartHeading">Heat Pump</div>

                <div className="row tableHeading">
                  <div className="col-lg-1">#</div>
                  <div className="col-lg-6">Label</div>
                  <div className="col-lg-4">Value</div>
                </div>
                <div className="scrollContentHeat">
                  {physicalDevices.map((physicalDevice) => {
                    const latestData = getLatestData(physicalDevice);
                    const equipmentLabel =
                      physicalDevice.deviceProfile?.equipmentLabel;

                    if (
                      equipmentLabel &&
                      equipmentLabel === "Heat Pump" &&
                      latestData
                    ) {
                      const sortedData = latestData
                        .slice()
                        .sort((a, b) =>
                          a.label.trim().localeCompare(b.label.trim())
                        );

                      return sortedData.map((data, dataIndex) => (
                        <div
                          className="row tableDataText"
                          key={dataIndex}
                          style={{ marginLeft: "0px" }}
                        >
                          <div className="col-lg-1">{dataIndex + 1}</div>
                          <div className="col-lg-6">{data.label}</div>
                          <div className="col-lg-4">
                            {data.value !== undefined
                              ? Number(data.value) % 1 !== 0
                                ? Number(data.value).toFixed(2)
                                : Number(data.value).toFixed(0)
                              : "N/A"}
                            {data.unit}
                          </div>
                        </div>
                      ));
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(connect(mapStateToProps, {})(heat));
