const initialState = {
  listViewData: null,
  loader: false,
};

export default function listViewReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_SUBTYPES_RECEIVED": {
      return {
        ...state,
        subtypes: action.data,
      };
    }
    case "ALL_GROUPS_RECEIVED": {
      return {
        ...state,
        allGroups: action.data,
      };
    }
    case "ALL_DEVICETYPES_RECEIVED": {
      return {
        ...state,
        allDeviceTypes: action.data,
      };
    }

    case "PROJECT_DATA_ALL": {
      return {
        ...state,
        allProjectData: action.data,
      };
    }

    case "NOTIFICATION_DATA_ALL": {
      return {
        ...state,
        allNotificationData: action.data,
      };
    }
    
    case "MARK_ONE_NOTIFICATION": {
      return {
        ...state,
        markOneNotificationData: action.data,
      };
    }
    

    case "NO_INTERNET": {
      return {
        ...state,
        noInternet: action.data,
      };
    }

    case "NO_INTERNET_CLEAR": {
      return {
        ...state,
        noInternet: undefined,
      };
    }

    case "PROJECT_DATA_CLEAR": {
      return {
        ...state,
        allChartData: null,
      };
    }
    case "CHART_DATA_ALL": {
      return {
        ...state,
        allChartData: action.data,
      };
    }
    case "CLEAR_LOADER": {
      return { ...state, loader: false };
    }
    case "START_LOADER": {
      return { ...state, loader: true };
    }
    case "REQUESTED_MEASUREMENT": {
      return {
        ...state,
        lastSeenDataResponse: action.data,
      };
    }
    case "PROJECT_MEMBERS_DETAILS_VIEW": {
      return {
        ...state,
        projectMemberDetails: action.data,
      };
    }
    case "SITE_MODE_CHANGED": {
      return {
        ...state,
        siteModeChanged: action.data,
      };
    }

    case "SITE_MODE_BATTERY_CHANGED": {
      return {
        ...state,
        siteModeBatteryChanged: action.data,
      };
    }

    case "SITE_MODE_SHOW_WARNING": {
      return {
        ...state,
        siteModeShowWarning: action.data,
      };
    }

    case "BATTERY_MODE_SHOW_WARNING": {
      return {
        ...state,
        batteryModeShowWarning: action.data,
      };
    }

    case "RENAME_SITE_NAME": {
      return {
        ...state,
        renameSiteNameChanged: action.data,
      };
    }

    case "PROJECT_NAME_DATA_CLEAR": {
      return {
        ...state,
        renameSiteNameChanged: undefined,
      };
    }
    case "PROJECT_MEMBER_DATA_CLEAR": {
      return {
        ...state,
        projectMemberDetails: undefined,
      };
    }

    case "PROJECT_SITE_MODE_DATA_CLEAR": {
      return {
        ...state,
        siteModeChanged: undefined,
        siteModeBatteryChanged: undefined,
        siteModeShowWarningForCurtailment: undefined,
      };
    }

    case "CHANGE_ACCESS_TYPE_MEMBER": {
      return {
        ...state,
        changeAccessType: action.data,
      };
    }

    case "ADD_MEMBER_CREATED": {
      return {
        ...state,
        addMemberCreated: action.data,
      };
    }

    case "ADD_MEMBER_FAILED": {
      return {
        ...state,
        addMemberFailed: action.data,
      };
    }

    case "DELETE_MEMBER_SUCCESS": {
      return {
        ...state,
        deleteMemberSuccess: action.data,
      };
    }

    case "DELETE_MEMBER_FAILED": {
      return {
        ...state,
        deleteMemberFailed: action.data,
      };
    }

    case "ADD_MEMBER_CLEAR": {
      return {
        ...state,
        addMemberCreated: undefined,
        addMemberFailed: undefined,
      };
    }

    case "CHANGE_MEMBER_ACCESS_CLEAR": {
      return {
        ...state,
        changeAccessType: undefined,
      };
    }
    case "DELETE_MEMBER_CLEAR": {
      return {
        ...state,
        deleteMemberSuccess: undefined,
        deleteMemberFailed: undefined,
      };
    }

    default: {
      return state;
    }
  }
}
