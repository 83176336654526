import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import Chart from "react-apexcharts";

class SolorChart extends Component {
  state = {
    formInput: {
      chartList: [],
      options: [],
      series: [],
      chartNameView: [],
      chartUnit: [],
    },
  };
  componentDidMount = () => {};
  componentDidUpdate = () => {};

  render() {
    const formInput = { ...this.state.formInput };
    formInput.chartNameView = "Active Power (Inverter)";
    formInput.chartUnit = "kW";
    formInput.chartList = this.props.chartViewData;
    let dataToRenderMaxValue = [];

    dataToRenderMaxValue = [
      ...formInput.chartList?.map((dataPoint) => dataPoint?.avg_val),
    ];

    const labels = [
      ...formInput.chartList.map(
        (dataPoint) => dataPoint.timestamp_seconds * 1000
      ),
    ];
    formInput.series = [
      {
        name: "Active Power",
        data: dataToRenderMaxValue,
        type: "line",
        toolbar: {
          title: {
            text: formInput?.chartUnit,
          },
        },
      },
    ];
    formInput.options = {
      chart: {
        height: 750,
        type: "line",
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: ["#bc5090"],
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        fontFamily: "Roboto Flex",
        fontSize: "14px",
        fontWeight: "100",
        height: 40,
        formatter: (seriesName) => {
          const color = "#bc5090";
          const col = "#fff";
          return `<span class="legend-item" style="background-color: ${color};color: ${col};">${seriesName}</span>`;
        },
        markers: {
          width: 0,
          height: 0,
          radius: 0,
        },
      },
      title: {
        text: formInput?.chartNameView,
        align: "left",
        className: "chartNameDiv",
        style: {
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "Roboto Flex, sans-serif",
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        x: {
          format: "HH:mm  dd/MM/yyyy",
        },
        y: {
          formatter: function (value) {
            return value ? value.toFixed(2) + " " + formInput?.chartUnit : "";
          },
          title: {
            formatter: function (seriesName) {
              return seriesName + ": ";
            },
          },
        },
      },
      stroke: {
        curve: "stepline",
      },
      xaxis: {
        tooltip: {
          enabled: false,
          formatter: function (val) {
            return new Date(val).toLocaleString();
          },
        },
        categories: labels,
        type: "datetime",
        tickPlacement: "on",
        labels: {
          datetimeUTC: false,
          showDuplicates: false,
        },
      },
      yaxis: {
        show: true,
        seriesName: ["Active Power"],
        labels: {
          formatter: (value) => {
            return Number.isInteger(value)
              ? value?.toFixed(0)
              : value?.toFixed(2);
          },
        },
        title: {
          text: formInput?.chartUnit,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        labels: {
          formatter: (value) => {
            return Number.isInteger(value)
              ? value?.toFixed(0)
              : value?.toFixed(2);
          },
        },
      },
    };

    return (
      <div>
        <Chart
          options={formInput.options}
          series={formInput.series}
          height={300}
          width="100%"
          marginLeft={12}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(SolorChart));
