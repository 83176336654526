import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";

import battery0Plus from "../../assets/battery/battery0-5%.png";
import battery6Plus from "../../assets/battery/battery6-20%.png";
import battery20Plus from "../../assets/battery/battery21-40%.png";
import battery40Plus from "../../assets/battery/battery41-60%.png";
import battery60Plus from "../../assets/battery/battery61-80%.png";
import battery80Plus from "../../assets/battery/battery81-94%.png";
import battery95Plus from "../../assets/battery/battery95-100%.png";
import battery100 from "../../assets/battery/battery100%.png";

class batteryCharge extends Component {
  state = {
    lastSeenUpdate: false,
    alertVisible: false,
  };

  render() {
    let { groupId } = this.props.match.params;
    const data = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );

    const batteryStateOfCharge =
      data[0]?.latestSummary?.BatteryStateOfCharge?.average;
    const batteryTarget = data[0]?.latestSummary?.BatteryTarget?.average;

    const batteryStateOfImage =
      batteryStateOfCharge === 100 || batteryStateOfCharge > 100
        ? battery100
        : batteryStateOfCharge > 94 && batteryStateOfCharge < 100
        ? battery95Plus
        : batteryStateOfCharge > 80 && batteryStateOfCharge < 95
        ? battery80Plus
        : batteryStateOfCharge > 60 && batteryStateOfCharge < 81
        ? battery60Plus
        : batteryStateOfCharge > 40 && batteryStateOfCharge < 61
        ? battery40Plus
        : batteryStateOfCharge > 21 && batteryStateOfCharge < 41
        ? battery20Plus
        : batteryStateOfCharge > 5 && batteryStateOfCharge < 21
        ? battery6Plus
        : battery0Plus;
    const batteryTargetOfImage =
      batteryTarget === 100 || batteryTarget > 100
        ? battery100
        : batteryTarget > 94 && batteryTarget < 100
        ? battery95Plus
        : batteryTarget > 80 && batteryTarget < 95
        ? battery80Plus
        : batteryTarget > 60 && batteryTarget < 81
        ? battery60Plus
        : batteryTarget > 40 && batteryTarget < 61
        ? battery40Plus
        : batteryTarget > 21 && batteryTarget < 41
        ? battery20Plus
        : batteryTarget > 5 && batteryTarget < 21
        ? battery6Plus
        : battery0Plus;
    return (
      <div>
        <div className="d-flex">
          {" "}
          <div className="col-lg-11 col-12">
            <div className="batteryStatusHeading">
              {" "}
              Current and Target State of Charge (Battery){" "}
            </div>
            <div className="batteryLine"></div>

            <table className="equipmentTable">
              <thead>
                <tr className="battery-tr">
                  <th className="col-md-6 batteryStatusHeading">Current</th>{" "}
                  <th className="col-md-1"></th>
                  <th className="col-md-5 batteryStatusHeading">Target</th>
                </tr>
              </thead>{" "}
              <tbody>
                <tr className="battery-row-tr">
                  <td className="col-md-2">
                    <img
                      src={batteryStateOfImage}
                      title={"battery " + batteryStateOfCharge + "% charge"}
                      alt=""
                    />
                  </td>
                  <td className="col-md-4">
                    <div className="batteryView">
                      {" "}
                      <div className={"batteryValueInPercentage"}>
                        {batteryStateOfCharge}
                      </div>{" "}
                      <div className="percentageName"> % </div>
                    </div>
                  </td>
                  <td className="col-md-2">
                    <img
                      src={batteryTargetOfImage}
                      title={"battery " + batteryStateOfCharge + "% charge"}
                      alt=""
                    />
                  </td>
                  <td className="col-md-4">
                    <div className="batteryView">
                      {" "}
                      <div className={"batteryValueInPercentage"}>
                        {batteryTarget}
                      </div>{" "}
                      <div className="percentageName"> % </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
  };
}

export default withRouter(connect(mapStateToProps, {})(batteryCharge));
