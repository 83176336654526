import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from "react-moment";
import {
  getAllNotification,
  getMarkOneNotification,
  getAllMarkNotification,
} from "../../actions/list-view";
import {
  closeNotificationPopup,
  openNotificationPopup,
} from "../../actions/screen-activities";

import "./styles.css";
import LoaderRoller from "../../components/loader/LoaderRoller";

class NotificationList extends Component {
  state = {
    formInput: {
      notificationId: "",
      subject: "",
      changeNotification: false,
    },
  };
  componentDidMount = async () => {
    this.props.getAllNotification(this.props.user?.id_user);
  };

  readThisNotification = (notificationId, subject) => {
    const formInput = { ...this.state.formInput };
    formInput.notificationId = notificationId;
    formInput.subject = subject;
    formInput.changeNotification = true;
    this.setState({ formInput });

    this.props.getMarkOneNotification(notificationId, this.props.user?.id_user);
  };

  readAllNotification = () => {
    this.props.getAllMarkNotification(this.props.user?.id_user);
  };

  render() {
    const notificationsWithin30Days =
      this.props.allNotificationData?.items?.filter((data) => {
        const currentTime = new Date().getTime();
        const notificationTime = data.created_at * 1000;
        const ageInMilliseconds = currentTime - notificationTime;

        // Only include notifications that are less than 30 days old
        const daysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        return ageInMilliseconds <= daysInMilliseconds;
      });
    const countNoOfUnreadNotification = notificationsWithin30Days?.filter(
      (item) => item.return_status === "unread"
    ).length;

    return (
      <Fragment>
        <div
          className={`${
            this.props.noticationListPopup ? "d-flex" : "d-none"
          } justify-content-end`}
        >
          <div className="notification-watt-dialog">
            <div className="notificationBar">
              <div className="NotificationHeader">Notifications</div>
              <div>
                {countNoOfUnreadNotification !== 0 ? (
                  <button
                    className="markasAllRead"
                    onClick={() => {
                      this.readAllNotification();
                    }}
                  >
                    Mark as all read
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            {notificationsWithin30Days?.length > 0 ? (
              notificationsWithin30Days.map((data) => (
                <table
                  className="table table-hover"
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                  key={data.id_notification_message}
                >
                  <tbody className="table-body p-2">
                    <tr
                      onClick={() => {
                        data?.return_status === "unread" &&
                        data?.redirect_link !== null &&
                        data?.redirect_link !== undefined
                          ? this.readThisNotification(
                              data?.id_notification_message,
                              data?.subject
                            )
                          : this.readThisNotification(
                              data?.id_notification_message,
                              data?.subject
                            );
                      }}
                    >
                      <td colSpan="2">
                        <div className="alignRow">
                          <div className="notification-title">
                            {data?.subject}
                          </div>
                          <div>
                            {data?.return_status === "unread" ? (
                              <div className="unreadNotification">new</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="notification-subtitle-bottom">
                          <Moment format="DD MMM YYYY hh:mm A">
                            {data.created_at * 1000}
                          </Moment>
                        </div>
                        <div className="notification-subtitle-top">
                          {data?.redirect_link !== null &&
                          data?.redirect_link !== undefined ? (
                            <a
                              href={data.redirect_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="notificationLink"
                            >
                              {data?.body}
                              <br />
                            </a>
                          ) : (
                            data?.body
                          )}{" "}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))
            ) : notificationsWithin30Days?.length === 0 ? (
              <div style={{ marginLeft: "13px" }}>
                No notification found for this moment.
              </div>
            ) : (
              <LoaderRoller />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    noticationPopup: state.screenActivitiesReducer?.noticationPopup,
    allNotificationData: state.listViewReducer?.allNotificationData,
    markOneNotificationData: state.listViewReducer?.markOneNotificationData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeNotificationPopup,
    openNotificationPopup,
    getAllNotification,
    getMarkOneNotification,
    getAllMarkNotification,
  })(NotificationList)
);
