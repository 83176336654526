import axios from "axios";
//import { newRedirect } from "./redirect";
import { showWarning } from "./notification";
import CONFIG from "../config";
const { baseUrl } = CONFIG;
const ALL_SUBTYPES_RECEIVED = "ALL_SUBTYPES_RECEIVED";
const ALL_GROUPS_RECEIVED = "ALL_GROUPS_RECEIVED";
const ALL_DEVICETYPES_RECEIVED = "ALL_DEVICETYPES_RECEIVED";
const PROJECT_DATA_ALL = "PROJECT_DATA_ALL";
const CHART_DATA_ALL = "CHART_DATA_ALL";
const REQUESTED_MEASUREMENT = "REQUESTED_MEASUREMENT";
const PROJECT_MEMBERS_DETAILS_VIEW = "PROJECT_MEMBERS_DETAILS_VIEW";
const SITE_MODE_CHANGED = "SITE_MODE_CHANGED";
const RENAME_SITE_NAME = "RENAME_SITE_NAME";
const PROJECT_MEMBER_DATA_CLEAR = "PROJECT_MEMBER_DATA_CLEAR";
const PROJECT_DATA_CLEAR = "PROJECT_DATA_CLEAR";
const PROJECT_NAME_DATA_CLEAR = "PROJECT_NAME_DATA_CLEAR";
const PROJECT_SITE_MODE_DATA_CLEAR = "PROJECT_SITE_MODE_DATA_CLEAR";
const ADD_MEMBER_CREATED = "ADD_MEMBER_CREATED";
const ADD_MEMBER_FAILED = "ADD_MEMBER_FAILED";
const ADD_MEMBER_CLEAR = "ADD_MEMBER_CLEAR";
const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
const DELETE_MEMBER_FAILED = "DELETE_MEMBER_FAILED";
const DELETE_MEMBER_CLEAR = "DELETE_MEMBER_CLEAR";
const CHANGE_ACCESS_TYPE_MEMBER = "CHANGE_ACCESS_TYPE_MEMBER";
const NO_INTERNET = "NO_INTERNET";
const CLEAR_LOADER = "CLEAR_LOADER";
const START_LOADER = "START_LOADER";
const NO_INTERNET_CLEAR = "NO_INTERNET_CLEAR";
const SITE_MODE_SHOW_WARNING = "SITE_MODE_SHOW_WARNING";
const SITE_MODE_BATTERY_CHANGED = "SITE_MODE_BATTERY_CHANGED";
const BATTERY_MODE_SHOW_WARNING = "BATTERY_MODE_SHOW_WARNING";
const NOTIFICATION_DATA_ALL = "NOTIFICATION_DATA_ALL";

const getAllProjectSuccess = (data) => ({
  type: PROJECT_DATA_ALL,
  data,
});

const getAllNotificationSuccess = (data) => ({
  type: NOTIFICATION_DATA_ALL,
  data,
});

const noInternet = (data) => ({
  type: NO_INTERNET,
  data,
});

const changeAccessTypeForMember = (data) => ({
  type: CHANGE_ACCESS_TYPE_MEMBER,
  data,
});

const getRenameSiteNameSuccess = (data) => ({
  type: RENAME_SITE_NAME,
  data,
});

const clearProjectDataSuccess = (data) => ({
  type: PROJECT_DATA_CLEAR,
  data,
});

const clearSiteModeChangeSuccess = (data) => ({
  type: PROJECT_SITE_MODE_DATA_CLEAR,
  data,
});
const clearProjectNameDataSuccess = (data) => ({
  type: PROJECT_NAME_DATA_CLEAR,
  data,
});

const clearProjectMemberDataSuccess = (data) => ({
  type: PROJECT_MEMBER_DATA_CLEAR,
  data,
});

const clearNoInternetSuccess = (data) => ({
  type: NO_INTERNET_CLEAR,
  data,
});

const getAllChartDataSuccess = (data) => ({
  type: CHART_DATA_ALL,
  data,
});

const getAllSubtypesSuccess = (data) => ({
  type: ALL_SUBTYPES_RECEIVED,
  data,
});

const siteModeUpdateSuccess = (data) => ({
  type: SITE_MODE_CHANGED,
  data,
});

const siteModeBatteryUpdateSuccess = (data) => ({
  type: SITE_MODE_BATTERY_CHANGED,
  data,
});

const siteModeShowWaringOnly = (data) => ({
  type: SITE_MODE_SHOW_WARNING,
  data,
});

const batteryModeShowWaringOnly = (data) => ({
  type: BATTERY_MODE_SHOW_WARNING,
  data,
});

const getAllGroupsSuccess = (data) => ({
  type: ALL_GROUPS_RECEIVED,
  data,
});

const getDeviceTypesSuccess = (data) => ({
  type: ALL_DEVICETYPES_RECEIVED,
  data,
});

const getAllRequestedMeasurementData = (data) => ({
  type: REQUESTED_MEASUREMENT,
  data,
});

const getMemberDetailsForSiteSuccess = (data) => ({
  type: PROJECT_MEMBERS_DETAILS_VIEW,
  data,
});

const addMemberSuccess = (data) => ({
  type: ADD_MEMBER_CREATED,
  data,
});

const addMemberFailed = (data) => ({
  type: ADD_MEMBER_FAILED,
  data,
});

const getDeleteSiteMemberSuccess = (data) => ({
  type: DELETE_MEMBER_SUCCESS,
  data,
});

const getDeleteSiteMemberFailed = (data) => ({
  type: DELETE_MEMBER_FAILED,
  data,
});
const clearAddNewMemberSuccess = (data) => ({
  type: ADD_MEMBER_CLEAR,
  data,
});
const clearDeleteSiteMemberSuccess = (data) => ({
  type: DELETE_MEMBER_CLEAR,
  data,
});
const startLoader = () => ({
  type: START_LOADER,
});

const stopLoader = () => ({
  type: CLEAR_LOADER,
});

export const getAllNotification = (userId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(
      `${baseUrl}/notification-message?filter[id_user]=${userId}&sort=-created_at`
    )
    .then((res) => {
      dispatch(getAllNotificationSuccess(res.data));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getMarkOneNotification =
  (notificationId, userId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/notification-message/${notificationId}/mark-as-read`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {},
    })
      .then((res) => {
        // dispatch(getMarkOneNotificationSuccess(res.data));
        dispatch(getAllNotification(userId));
      })
      .catch((err) => {
        if (!err?.response) {
          dispatch(noInternet("no internet"));
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getAllMarkNotification = (userId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios({
    method: "PUT",
    url: `${baseUrl}/notification-message/${userId}/mark-all-as-read`,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    },
    referrerPolicy: "no-referrer",
    data: {},
  })
    .then((res) => {
      // dispatch(getMarkOneNotificationSuccess(res.data));
      dispatch(getAllNotification(userId));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getAllProject = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(
      `${baseUrl}/group?filter[deleted_at]=NULL&filter[type]=50100&sort=label&sort=created_at&expand=physicalDevices.deviceProfile,meta,countConnectedGateways,countDisconnectedGateways,latestSummary,physicalDevices,location,physicalDevices.latestData,groupDataCategories,groupMonitor,weatherStatus,access&per-page=50`
    )
    .then((res) => {
      dispatch(getAllProjectSuccess(res.data));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const siteModeBatteryUpdate =
  (siteDetails, groupId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/group/${groupId}/update-group-monitor`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        // label: siteDetails.sitelabel,
        // id_group: siteDetails.id_group,
        id_group_monitor: siteDetails.monitorGroup,
        // className: "MonitorWattMaestro",
        // source_device: siteDetails.source_device,
        batteryMode: siteDetails.batteryModeValue,
        //    label: projectName,
      },
    })
      .then((res) => {
        dispatch(siteModeBatteryUpdateSuccess(res));
        dispatch(getAllProject());
      })
      .catch((err) => {
        if (
          siteDetails.wattMaestroMode === 2 ||
          siteDetails.batteryModeValue === 2
        ) {
          dispatch(batteryModeShowWaringOnly(err?.response?.data?.message));
          dispatch(getAllProject());
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const siteModeUpdate =
  (siteDetails, groupId) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/group/${groupId}/update-group-monitor`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data:
        siteDetails.wattMaestroMode === 3
          ? {
              //   label: siteDetails.sitelabel,
              //  id_group: siteDetails.id_group,
              id_group_monitor: siteDetails.monitorGroup,
              // className: "MonitorWattMaestro",
              // source_device: siteDetails.source_device,
              wattMaestroMode: siteDetails.wattMaestroMode,
              configured_set_point_threshold: siteDetails.loadBalanceValue,
            }
          : {
              // label: siteDetails.sitelabel,
              // id_group: siteDetails.id_group,
              id_group_monitor: siteDetails.monitorGroup,
              // className: "MonitorWattMaestro",
              // source_device: siteDetails.source_device,
              wattMaestroMode: siteDetails.wattMaestroMode,
              //    label: projectName,
            },
    })
      .then((res) => {
        dispatch(siteModeUpdateSuccess(res));
        dispatch(getAllProject());
      })
      .catch((err) => {
        if (err?.response?.data?.message !== undefined) {
          dispatch(siteModeShowWaringOnly(err?.response?.data?.message));
          dispatch(getAllProject());
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const getAllChartData = (filterData) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  dispatch(startLoader());
  axios
    .get(`${baseUrl}/data-downsampled?${filterData}&sort=timestamp_seconds`)
    .then((res) => {
      dispatch(getAllChartDataSuccess(res.data));
      dispatch(stopLoader());
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }

      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getMemberDetailsForSite = (groupId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/group/${groupId}?expand=access`)
    .then((res) => {
      dispatch(getMemberDetailsForSiteSuccess(res));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const getAllSubtypes = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios
    .get(`${baseUrl}/group/subtypes`)
    .then((res) => {
      dispatch(getAllSubtypesSuccess(res.data));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(showWarning(err.message));
    });
};

export const getAllGroups = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/device-type`)
    .then((res) => {
      dispatch(getAllGroupsSuccess(res.data));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(showWarning(err.message));
    });
};

export const getAllDeviceType = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/device-type`)
    .then((res) => {
      dispatch(getDeviceTypesSuccess(res.data));
    })
    .catch((err) => {
      if (!err?.response) {
        dispatch(noInternet("no internet"));
      }
      dispatch(showWarning(err.message));
    });
};

export const getrequestMeasurement =
  (id, filterOptions) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    return axios
      .put(`${baseUrl}/physical-device/${id}/request-measurement`)
      .then((res) => {
        dispatch(getAllRequestedMeasurementData(res.data));

        if (res.status === 200) {
          dispatch(getAllChartData(filterOptions));
          dispatch(getAllProject());
        }
        return res.status;
      })
      .catch((err) => {
        if (!err?.response) {
          dispatch(noInternet("no internet"));
        }
        dispatch(showWarning(err.message));
        return err.response.status;
      });
  };

export const renameSiteName =
  (siteName, groupId, dataSaved) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    if (dataSaved !== undefined) {
      dispatch(clearProjectNameData());
    }
    axios({
      method: "PUT",
      url: `${baseUrl}/group/${groupId}`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        label: siteName,
      },
    })
      .then((res) => {
        dispatch(getRenameSiteNameSuccess(res));
        dispatch(getAllProject());
      })
      .catch((err) => {
        if (!err?.response) {
          dispatch(noInternet("no internet"));
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const addNewMember =
  (groupId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "POST",
      url: `${baseUrl}/group/${groupId}/user-invite`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        email: Email,
        type: Access,
      },
    })
      .then((res) => {
        dispatch(addMemberSuccess(res));
        dispatch(getMemberDetailsForSite(groupId));
      })
      .catch((err) => {
        if (!err?.response) {
          dispatch(noInternet("no internet"));
        }
        if (
          (err?.response?.data?.message.includes("422"),
          err.message.includes("422"))
        ) {
          dispatch(addMemberFailed("422"));
          dispatch(getMemberDetailsForSite(groupId));
        }

        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const deleteSiteMember =
  (groupId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios
      .delete(`${baseUrl}/group/${groupId}/user-invite`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        referrerPolicy: "no-referrer",
        data: {
          email: Email,
          type: Access,
        },
      })
      .then((res) => {
        dispatch(getDeleteSiteMemberSuccess(res));
        dispatch(getMemberDetailsForSite(groupId));
      })
      .catch((err) => {
        if (!err?.response) {
          dispatch(noInternet("no internet"));
        }
        if (
          (err?.response?.data?.message.includes("422"),
          err.message.includes("422"))
        ) {
          dispatch(getDeleteSiteMemberFailed("422"));
          dispatch(getMemberDetailsForSite(groupId));
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };

export const clearDeleteSiteMember = () => (dispatch) => {
  dispatch(clearDeleteSiteMemberSuccess());
};
export const clearAddNewMember = () => (dispatch) => {
  dispatch(clearAddNewMemberSuccess());
};
export const clearProjectNameData = () => (dispatch) => {
  dispatch(clearProjectNameDataSuccess());
};

export const clearProjectMemberData = () => (dispatch) => {
  dispatch(clearProjectMemberDataSuccess());
};

export const clearProjectData = () => (dispatch) => {
  dispatch(clearProjectDataSuccess());
};

export const clearSiteModeChange = () => (dispatch) => {
  dispatch(clearSiteModeChangeSuccess());
};

export const clearNoInternet = () => (dispatch) => {
  dispatch(clearNoInternetSuccess());
};
export const changeAccessTypeMember =
  (groupId, Email, Access) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

    axios({
      method: "PUT",
      url: `${baseUrl}/group/${groupId}/user-invite`,
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.token}`,
      },
      referrerPolicy: "no-referrer",
      data: {
        email: Email,
        type: Access,
      },
    })
      .then((res) => {
        dispatch(changeAccessTypeForMember(res.status));
        dispatch(getMemberDetailsForSite(groupId));
        return res.status;
      })
      .catch((err) => {
        if (
          (err?.response?.data?.message.includes("422"),
          err.message.includes("422"))
        ) {
          dispatch(changeAccessTypeForMember(err.response.status));
          dispatch(getMemberDetailsForSite(groupId));
          return err.response.status;
        }
        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
        return err.response.status;
      });
  };
