import axios from "axios";
import CONFIG from "../config";

const CLEAR_ALERTS = "CLEAR_ALERTS";
const ADD_WARNING = "ADD_WARNING";
const ADD_DANGER = "ADD_DANGER";
const ADD_INFO = "ADD_INFO";
const CLEAR_ONE_NOTIFICATION = "CLEAR_ONE_NOTIFICATION";

const FETCH_NOTIFICATION_PREFERENCES_SUCCESS =
  "FETCH_NOTIFICATION_PREFERENCES_SUCCESS";
const FETCH_NOTIFICATION_PREFERENCES_FAILURE =
  "FETCH_NOTIFICATION_PREFERENCES_FAILURE";

const { baseUrl } = CONFIG;

const alertsCleared = () => ({
  type: CLEAR_ALERTS,
});

const alertWarning = (data) => ({
  type: ADD_WARNING,
  data,
});

const alertInfo = (data) => ({
  type: ADD_INFO,
  data,
});

const alertDanger = (data) => ({
  type: ADD_DANGER,
  data,
});

const clearOneNotificationSuccess = (id) => ({
  type: CLEAR_ONE_NOTIFICATION,
  id,
});

export const fetchNotificationPreferencesSuccess = (data) => ({
  type: FETCH_NOTIFICATION_PREFERENCES_SUCCESS,
  data,
});

export const fetchNotificationPreferencesFailure = (error) => ({
  type: FETCH_NOTIFICATION_PREFERENCES_FAILURE,
  error,
});

export const clearAlerts = () => (dispatch) => {
  dispatch(alertsCleared());
};

export const showWarning = (data) => (dispatch) => {
  dispatch(alertWarning(data));
};

export const showInfo = (data) => (dispatch) => {
  dispatch(alertInfo(data));
};

export const showDanger = (data) => (dispatch) => {
  dispatch(alertDanger(data));
};

export const clearOneNotification = (id) => (dispatch) => {
  dispatch(clearOneNotificationSuccess(id));
};

export const fetchNotificationPreferences = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  // userId = "878ca6eb-e381-4791-a4f5-0a58cf61d740";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/notification-preference`)
    .then((res) => {
      dispatch(fetchNotificationPreferencesSuccess(res));
    })
    .catch((err) => {
      dispatch(fetchNotificationPreferencesFailure(err?.response?.status));
      dispatch(
        showWarning({ body: err?.response?.data?.message || err.message })
      );
    });
};

export const updateNotificationPreferences =
  (updates) => (dispatch, getState) => {
    const { token } = getState().userReducer;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    const updatePromises = updates.map((data) =>
      axios({
        method: "PUT",
        url: `${baseUrl}/notification-preference/${data.id_notification_preference}`,
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        data,
      })
    );
    Promise.all(updatePromises)
      .then((res) => {
        dispatch(fetchNotificationPreferences(res));
      })
      .catch((err) => {
        console.error("Error updating preferences:", err);
        dispatch(fetchNotificationPreferencesFailure(err.message));

        dispatch(
          showWarning({ body: err?.response?.data?.message || err.message })
        );
      });
  };
