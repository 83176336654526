import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import ReactApexChart from "react-apexcharts";

class inverterChart extends Component {
  state = {
    lastSeenUpdate: false,
    alertVisible: false,
    series: [],
    options: [],
  };

  render() {
    let { groupId } = this.props.match.params;
    const isMobile = window.innerWidth < 768;
    const inverterChartUnit = this.props.inverterChartUnit;

    const inverterData =
      this.props.inverterDataSets !== ""
        ? this.props.allChartData?.items.filter((n) =>
            this.props.inverterDataSets?.some(
              (data) => data === n.id_container_data_latest
            )
          )
        : this.props.allChartData?.items.filter(
            (n) => n.id_container_data_latest === this.props.inverterDataSets[0]
          );
    const separatedInverterData = (
      Array.isArray(this.props.inverterDataSets)
        ? this.props.inverterDataSets
        : Object.keys(this.props.inverterDataSets)
    ).reduce((acc, id) => {
      const filteredData = this.props.allChartData?.items.filter(
        (item) => item.id_container_data_latest === id
      );

      acc[id] = filteredData;
      return acc;
    }, {});
    let maxCount = 0;
    let inverterWithMaxCount = null;

    Object.entries(separatedInverterData).forEach(([id, data]) => {
      const count = data.length;
      if (count > maxCount) {
        maxCount = count;
        inverterWithMaxCount = id;
      }
    });

    const inverterDatas = separatedInverterData[inverterWithMaxCount] || [];
    const labels =
      inverterDatas?.map((dataPoint) => dataPoint?.timestamp_seconds * 1000) ||
      [];
    const colors = [
      "#bc5090",
      "#ff6361",
      "#ffbc42",
      "#6b4226",
      "#003f5c",
      "#58508d",
      "#d4578c",
    ];
    const seriesArray = [];

    if (!inverterData) {
      return [];
    }
    // const seriesData = inverterData.map((item) => {
    //   return isNaN(item.avg_val) || item.avg_val == null ? 0 : item.avg_val;
    // });
    const monitorData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const monitorValue = monitorData[0]?.physicalDevices.filter(
      (device) => device?.id_parent !== null
    );
    Object.keys(separatedInverterData).forEach((inverterId, index) => {
      const inverterData = separatedInverterData[inverterId];
      const seriesData = inverterData.map((item) => {
        return isNaN(item.avg_val) || item.avg_val == null ? 0 : item.avg_val;
      });
      const seriesColor = colors[index % colors.length];
      const monitorValueLabel = monitorValue[index];
      const label = monitorValue
        ? `Inverter: ${monitorValueLabel.label}`
        : `Inverter ${inverterId}`;
      seriesArray.push({
        name: label,
        data: seriesData,
        type: "line",
        color: seriesColor, // Can vary based on inverterId or other logic
      });
    });

    //  const maxValue = Math.max(...seriesData); // Get the maximum value from the data
    //  const minValue = Math.min(...seriesData);
    const maxValue = Math.max(...seriesArray.flatMap((s) => s.data)); // Get the max value from all series
    const minValue = Math.min(...seriesArray.flatMap((s) => s.data)); // Get the min value from all series

    const options = {
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const label = monitorValue
            ? `Inverter: ${monitorValue[seriesIndex].label}`
            : `Inverter`;
          const seriesColor = w.config.colors[seriesIndex];
          const unit = inverterChartUnit; // Get the unit for the series
          const value = series[seriesIndex][dataPointIndex]; // Get the current data point value
          const tooltipBackgroundColor = seriesColor || "#000000";

          return `
      <div class="custom-tooltip" style="background-color: ${tooltipBackgroundColor}; padding: 5px; border-radius: 5px;">
        <span style="color: white;">
        ${label}: ${
            value !== undefined
              ? Number(value) % 1 !== 0
                ? Number(value).toFixed(2)
                : Number(value).toFixed(0)
              : "0"
          } ${unit}
        </span>
      </div>
    `;
        },
      },
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      noData: {
        fontSize: "16px",
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      title: {
        text: "Active Power (Inverter)",
        align: "left",
        offsetX: 0,
        offsetY: -4,
        style: {
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "Roboto Flex, sans-serif",
        },
        floating: false,
      },

      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        fontFamily: "Roboto Flex",
        fontSize: "14px",
        fontWeight: "100",
        height: 40,
        labels: {
          colors: colors,
          formatter: function (seriesName) {
            return `<span class="legend-item">${seriesName}</span>`;
          },
        },
        markers: {
          width: 0,
          height: 0,
          radius: 0,
          fillColors: colors,
        },

        formatter: (seriesName, opts) => {
          const color = colors[opts.seriesIndex];
          return `<span class="legend-item" style="background-color: ${color}; color: #fff; padding: 4px 8px; border-radius: 4px;">${seriesName}</span>`;
        },
      },
      stroke: {
        curve: "stepline",
      },

      xaxis: {
        tooltip: {
          enabled: true,
        },
        categories: labels,
        type: "datetime",
        tickPlacement: "on",
        labels: {
          datetimeUTC: false,
          showDuplicates: false,
        },
      },
      yaxis: {
        show: true,
        seriesName: ["Active Power"],
        min: minValue - 1, // Optional: Add a little padding below the minimum
        max: maxValue + 1, // Optional: Add a little padding above the maximum
        labels: {
          formatter: (value) => {
            return Number.isInteger(value)
              ? value?.toFixed(0)
              : value?.toFixed(2);
          },
        },
        title: {
          text: inverterChartUnit,
          rotate: -90,
          offsetX: isMobile ? 10 : 0,
          offsetY: 0,
          style: {
            //  color: "#DC143C",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 700,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
    };

    return (
      <div>
        {/* {inverterData ? ( */}
        {seriesArray.length > 0 ? (
          <ReactApexChart
            options={options}
            series={seriesArray}
            type="line"
            height={300}
            width="100%"
          />
        ) : (
          "No data available for State of Charge"
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
  };
}

export default withRouter(connect(mapStateToProps, {})(inverterChart));
