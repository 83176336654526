import React, { Component, Fragment } from "react";
import CONFIG from "../../config";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./style.css";
import { showInfo } from "../../actions/notification";
import { Link } from "react-router-dom";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountOr,
  AccountTitle,
  AccountWrap,
} from "../shared/components/account/AccountElements";
import WattMaestro from "../../assets/WattMaestro_logo_new.png";

const { loginUrl, clientId, clientSecret } = CONFIG;

class Login extends Component {
  state = {
    formInput: {
      registerCheck: false,
    },
  };

  handleRegister = () => {
    const formInput = { ...this.state.formInput };
    formInput.registerCheck = true;
    this.setState({ formInput });
  };
  render() {
    return (
      <Fragment>
        <div className="logo-watt roboto-flex-uniquifier-user d-flex">
          <div className="logo-login-icon-container">
            <a href="/" className="wattTitleLogo">
              <img
                src={WattMaestro}
                title="WattMaestro"
                alt="WattMaestro Logo"
              />
            </a>
          </div>
        </div>
        <div className="stopScroll">
          <AccountWrap>
            <AccountContent>
              <AccountCard>
                <AccountHead>
                  <AccountTitle>
                    Welcome to
                    <AccountLogo> WattMaestro</AccountLogo>
                  </AccountTitle>
                  <h4 className="subhead">
                    Optimize Energy Management, Smarter
                  </h4>
                </AccountHead>

                <AccountOr></AccountOr>
                <div style={{ display: "flex" }}>
                  {" "}
                  <a
                    href={decodeURIComponent(
                      `${loginUrl}?ClientId=${clientId}&ClientSecret=${clientSecret}`
                    )}
                    rel="noopener noreferrer"
                    className="login-btn-watt"
                  >
                    <div className="signIn-Watt">Sign In</div>
                  </a>
                  <Link
                    to="/Register"
                    rel="noopener noreferrer"
                    className="register-btn-watt"
                  >
                    <div className="register-Watt">Create Account</div>
                  </Link>
                </div>
              </AccountCard>
            </AccountContent>
          </AccountWrap>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  };
}

export default withRouter(connect(mapStateToProps, { showInfo })(Login));
