import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import "./styles.css";
import { clearRedirect } from "../../actions/redirect";
import { tryUserAuth } from "../../actions/user";
import { routeChange } from "../../actions/screen-activities";
import {
  getAllGroups,
  getAllProject,
  clearProjectData,
} from "../../actions/list-view";
import Login from "../login/Login";
import Authorization from "../login/Authorization";
import CONFIG from "../../config";
import MainMenu from "../../components/menus/main-menu/MainMenu";
import Sites from "../sites/sites";
import Register from "../register/Register";
import Header from "../header/header";
import Home from "../left-navigation/Home";
import Userpage from "../left-navigation/User";
import Monitor from "../left-navigation/Monitor";
import SettingsDashboard from "../../components/menus/top-menu/SettingsDashboard";

const { userLogoutTimeout } = CONFIG;

const publicUrls = ["/"];
class Main extends Component {
  state = {
    authentificated: false,
    projectData: [],
    projectSelected: "",
    projectLabel: "",
    mainData: null,
    subData: null,
    subdataId: 123,
  };

  componentDidMount = () => {
    const isMainMenuVisible = this.props.location.pathname.startsWith("/site");
    if (isMainMenuVisible) {
      this.props.history.push("/");
    }

    this.props.routeChange();
    if (!this.props.user) {
      const userLeft = JSON.parse(localStorage.getItem("userLeftTime"));
      if (userLeft) {
        const timeNow = Date.now();
        if (timeNow - userLeft > userLogoutTimeout) {
          localStorage.clear();
          return;
        }
      }
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        this.props.tryUserAuth(user);
      } else {
        const authRoutRegExp = new RegExp("^/authorization", "i");
        if (!window.location.pathname.match(authRoutRegExp)) {
          this.props.history.push("/");
        }
      }
    }

    if (
      !window.location.pathname.startsWith("/") &&
      !window.location.pathname.startsWith("/authorization")
    ) {
      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        localStorage.setItem("userLeftTime", Date.now());
        return true;
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.redirectTo &&
      this.props.redirectTo !== prevProps.redirectTo
    ) {
      this.props.history.push(this.props.redirectTo);
      this.props.clearRedirect();
    }

    if (this.props.location !== prevProps.location) {
      this.props.routeChange();
      window.scrollTo(0, 0);
    }

    if (publicUrls.includes(window.location.pathname)) {
      if (
        this.props.user?.token?.token &&
        new Date(this.props.user?.token?.expires).getTime() >= Date.now()
      ) {
        if (this.state.projectSelected !== "") {
          this.props.history.push(`/site/home/${this.state.projectSelected}`);
        }

        if (this.state.projectData.length === 0) {
          this.props.getAllProject();
        }
      }
    }
  };

  handleProject = (langValue) => {
    this.setState({
      projectSelected: langValue,
    });
  };

  clearProject = (langValue) => {
    this.setState({ projectSelected: "", projectData: [] });
    this.props.clearProjectData();
  };

  render() {
    const formInput = { ...this.state.formInput };
    formInput.projectData =
      this.props.allProjectData === undefined
        ? this.state.projectData
        : this.props.allProjectData;
    const projectFinddetails = this.props.allProjectData
      ? this.props.allProjectData.items.filter(
          (h) => h.id_group === this.state.projectSelected
        )
      : [];
    formInput.projectLabel = projectFinddetails[0]?.label || "";
    // Check if MainMenu should be displayed
    const isMainMenuVisible = this.props.location.pathname.startsWith("/site");

    if (this.props.user) {
      return (
        <Fragment>
          <Header />
          {/* Show MainMenu only for `/site/*` routes */}
          {isMainMenuVisible && (
            <MainMenu
              userDetails={this.props.user}
              groupId={this.state.projectSelected}
              projectLabel={formInput.projectLabel}
              clearProject={this.clearProject}
            />
          )}
          <div>
            <Switch>
              <Route
                path="/settings/:userId?"
                render={(props) => (
                  <div className="margin-mob">
                    <SettingsDashboard {...props} />
                  </div>
                )}
              />
              <Route
                exact
                path="/"
                render={() => (
                  <Sites
                    projectAllData={this.props.allProjectData}
                    onSelectProject={this.handleProject}
                    allProjectDetailsData={this.props.allProjectDetailsData}
                  />
                )}
              />
              <Route path="/site/home/:groupId?" component={Home} />
              <Route path="/site/user/:groupId?" component={Userpage} />
              <Route path="/site/monitor/:groupId?" component={Monitor} />
              <Route path="/authorization" component={Authorization} />
              <Route path="/Register" component={Register} />
              <Route path="/" exact component={Login} />
            </Switch>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Switch>
            <Route path="/authorization" component={Authorization} />
            <Route path="/" exact component={Login} />
            <Route path="/Register" component={Register} />
          </Switch>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    redirectTo: state.redirectReducer.redirectTo,
    allGroups: state.listViewReducer?.allGroups,
    subtypes: state.listViewReducer?.subtypes,
    allProjectData: state.listViewReducer?.allProjectData,
    allProjectDetailsData: state.listViewReducer?.allProjectDetailsData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    clearRedirect,
    tryUserAuth,
    routeChange,
    getAllGroups,
    getAllProject,
    clearProjectData,
  })(Main)
);
