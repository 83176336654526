import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../login/style.css";
import "./styles.css";
import WattMaestroUser from "../../components/menus/top-menu/WattMaestroUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell } from "@fortawesome/pro-regular-svg-icons";
import {
  openUserPopup,
  closeUserPopup,
  closeNotificationPopup,
  openNotificationPopup,
} from "../../actions/screen-activities";
import { getAllNotification } from "../../actions/list-view";
import NotificationList from "../notification/notificationList";
import WattMaestro from "../../assets/WattMaestro_logo_new.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.notificationRef = React.createRef();
    this.userPopupRef = React.createRef();
  }

  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.props.getAllNotification(this.props.user?.id_user);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.notificationRef.current &&
      !this.notificationRef.current.contains(event.target) &&
      this.props.noticationPopup
    ) {
      //   this.props.closeNotificationPopup();
    }

    if (
      this.userPopupRef.current &&
      !this.userPopupRef.current.contains(event.target) &&
      this.props.topUserPopup
    ) {
      //   this.props.closeUserPopup();
    }
  };

  render() {
    const notificationsWithin30Days =
      this.props.allNotificationData?.items?.filter((data) => {
        const currentTime = new Date().getTime();
        const notificationTime = data.created_at * 1000;
        const ageInMilliseconds = currentTime - notificationTime;

        // Only include notifications that are less than 30 days old
        const daysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
        return ageInMilliseconds <= daysInMilliseconds;
      });
    const noOfUnreadNotification = notificationsWithin30Days?.filter(
      (item) => item.return_status === "unread"
    ).length;
    return (
      <Fragment>
        {" "}
        <div className="logoLoginImgDiv sticky">
          <div class="row" style={{ alignItems: "center" }}>
            <div class="col d-flex">
              {" "}
              <div className="logo-watt roboto-flex-uniquifier-user d-flex">
                <div className="logo-icon-container">
                  <a href="/" className="wattTitleLogo">
                    <img
                      src={WattMaestro}
                      title="WattMaestro"
                      alt="WattMaestro Logo"
                    />
                  </a>
                </div>
              </div>
            </div>{" "}
            <div class="col"></div>
            {this.props.userEmail !== undefined && (
              <div class="col d-flex justify-content-end">
                <div
                  className="user-name-watt roboto-flex-uniquifier-user d-flex"
                  style={{ marginBottom: "10px" }}
                >
                  <div
                    className="notification-icon-container btn"
                    //  className={this.props.noticationPopup ? "btn" : "btn"}
                    onClick={
                      this.props.noticationPopup
                        ? this.props.closeNotificationPopup
                        : this.props.openNotificationPopup
                    }
                    ref={this.notificationRef}
                    style={
                      noOfUnreadNotification > 0
                        ? {
                            backgroundColor: "lightgray",
                            borderRadius: "30px",
                          }
                        : this.props.noticationPopup
                        ? {
                            backgroundColor: "lightgray",
                            borderRadius: "30px",
                          }
                        : {
                            backgroundColor: "",
                          }
                    }
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "19px" }}
                      icon={faBell}
                      className="iconBlack"
                    />{" "}
                    {noOfUnreadNotification > 0 && (
                      <div className="notification-count">
                        {noOfUnreadNotification}
                      </div>
                    )}
                  </div>{" "}
                  <div
                    className="btn"
                    style={
                      this.props.topUserPopup
                        ? {
                            backgroundColor: "lightgray",
                            borderRadius: "30px",
                            marginLeft: "6px",
                          }
                        : {
                            backgroundColor: "",
                            marginLeft: "6px",
                          }
                    }
                    onClick={
                      this.props.topUserPopup
                        ? this.props.closeUserPopup
                        : this.props.openUserPopup
                    }
                    ref={this.userPopupRef}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "19px" }}
                      icon={faUser}
                      className="iconBlack"
                    />
                  </div>
                </div>
              </div>
            )}
            <NotificationList
              noticationListPopup={this.props.noticationPopup}
              user={this.props.user}
            />
            <WattMaestroUser
              topPopup={this.props.topUserPopup}
              userEmail={this.props.userEmail}
            />
          </div>
        </div>
        {/* <nav class="navbar navbar-light bg-light justify-content-between">
  <a class="navbar-brand">Navbar</a>
  <form class="form-inline">
    <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
    <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
  </form>
</nav> */}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    user: state.userReducer?.user,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
    noticationPopup: state.screenActivitiesReducer?.noticationPopup,
    allNotificationData: state.listViewReducer?.allNotificationData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
    closeNotificationPopup,
    openNotificationPopup,
    getAllNotification,
  })(Header)
);
