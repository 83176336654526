import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../style.css";
import { Link } from "react-router-dom";
import {
  faHouse as fahousesolid,
  faSquarePollVertical as faSquarePollVerticalsolid,
  faGear as faGearsolid,
} from "@fortawesome/pro-light-svg-icons";
import {
  faHouse,
  faSquarePollVertical,
  faMap,
  faGear,
} from "@fortawesome/pro-thin-svg-icons";
import BottomNavigation from "./BottomNavigation";

class MenuItems extends Component {
  state = {
    formInput: {
      groupId: "",
      hoveredItem: null,
    },
    isMobile: window.innerWidth < 768,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  handleMouseEnter = (itemId) => {
    this.setState({ hoveredItem: itemId });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredItem: null });
  };

  handleToRedirectSiteOverView = () => {
    this.props.clearProject();
    return this.props.history.push("/");
  };

  handleToRedirectOnMonitor = () => {
    this.props.clearProject();
    return this.props.history.push("/Monitor");
  };

  render() {
    const formInput = this.state.formInput;
    formInput.groupId = this.props.groupId;
    const { hoveredItem, isMobile } = this.state;
    const menuItems = [
      {
        label: "Home",
        fontsize: "17.5px",
        icon: faHouse,
        iconsolid: fahousesolid,
        route: `/site/home/${formInput.groupId}`,
      },
      {
        label: "Monitor",
        fontsize: "22px",
        icon: faSquarePollVertical,
        iconsolid: faSquarePollVerticalsolid,
        route: `/site/monitor/${formInput.groupId}`,
      },
      {
        label: "Site Settings",
        icon: faGear,
        iconsolid: faGearsolid,
        fontsize: "22px",
        route: `/site/user/${formInput.groupId}`,
      },
    ];

    return (
      <Fragment>
        {isMobile ? (
          <BottomNavigation
            groupId={formInput.groupId}
            handleToRedirectSiteOverView={this.handleToRedirectSiteOverView}
          />
        ) : (
          <div className="menu-items-container">
            <div className="menuscroll">
              <span
                className={
                  this.props.routeStart.startsWith("site")
                    ? this.state.isHovered
                      ? "active-left-menu-link-icon-shadow-map-mouse-hover"
                      : "active-left-menu-link-icon-shadow-map"
                    : ""
                }
                onMouseEnter={() => this.setState({ isHovered: true })}
                onMouseLeave={() => this.setState({ isHovered: false })}
              >
                <span onClick={this.handleToRedirectSiteOverView}>
                  <span className="menuleftcolor">
                    <FontAwesomeIcon
                      className="fa-lg"
                      icon={faMap}
                      style={{ color: "white" }}
                    />
                  </span>
                  <span
                    className={
                      this.state.isHovered ? "activesiteMap" : "inactive"
                    }
                  >
                    {this.props.projectLabel}
                  </span>
                </span>
              </span>
              <div className="leftMenucontainer">
                {menuItems.map((menuItem, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => this.handleMouseEnter(index)}
                    onMouseLeave={this.handleMouseLeave}
                    className=""
                  >
                    <span
                      className={
                        this.props.location.pathname === menuItem.route ||
                        hoveredItem === index
                          ? "active-left-menu-link-icon-shadow over"
                          : "over"
                      }
                    >
                      <span>
                        <Link
                          to={menuItem.route}
                          className={
                            this.props.location.pathname === menuItem.route ||
                            hoveredItem === index
                              ? "no-underscore left-menu-link-active"
                              : "no-underscore inactive-left-menu-link-icon-shadow"
                          }
                        >
                          <span className="menuleftcolor">
                            <FontAwesomeIcon
                              className="fa-lg"
                              style={{ fontSize: menuItem.fontsize }}
                              icon={
                                hoveredItem === index
                                  ? menuItem.iconsolid
                                  : menuItem.icon
                              }
                            />
                          </span>
                          <span
                            className={
                              hoveredItem === index
                                ? "active"
                                : "inactive"
                            }
                          >
                            {menuItem.label}
                          </span>
                        </Link>
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    routeStart: state.screenActivitiesReducer?.routeStart,
    subtypes: state.listViewReducer?.subtypes,
  };
}

export default withRouter(connect(mapStateToProps)(MenuItems));
