import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import { clearProjectData } from "../../actions/list-view";

import Chart from "react-apexcharts";
import WeatherStatus from "./weatherStatus";
import SolarIrradiance from "./solarIrradiance";

class HomeChart extends Component {
  constructor(props) {
    super(props);
    this.chartRefs = [];
  }
  state = {
    formInput: {
      chartList: [],
      options: [],
      series: [],
      chartNameView: [],
      chartUnit: [],
      restChartList: [],
    },
  };

  componentDidMount = () => {
    const formInput = { ...this.state.formInput };
    let chartdataList = [];
    let dat = 0;
    if (dat < this.props.dataSetsArray.length) {
      this.props.dataSetsArray.forEach((id, index) => {
        chartdataList[index] = this.props.allChartData?.items.filter(
          (m) => m.id_container_data_latest === id
        );
      });

      const IsSplitEnergyMeterPowerPositive = this.props.chartName.includes(
        "IsSplitEnergyMeterPowerPositive"
      );
      const IsSplitEnergyMeterPowerNegative = this.props.chartName.includes(
        "SplitEnergyMeterPowerNegative"
      );
      if (IsSplitEnergyMeterPowerNegative) {
        formInput.chartList = chartdataList;
      }
      if (IsSplitEnergyMeterPowerPositive) {
        formInput.restChartList =
          chartdataList.length > 1 ? chartdataList.slice(1) : chartdataList;
      }
      this.setState({ formInput });
      this.setState({ formInput }, () => {
        this.props.getSeriesData(this.state.formInput.series);
      });
      dat++;
    }
  };

  componentDidUpdate(prevProps) {
    // this.props.clearProjectData();
    if (
      prevProps.allChartData !== this.props.allChartData ||
      prevProps.dataSetsArray !== this.props.dataSetsArray
    ) {
      const formInput = { ...this.state.formInput };
      let chartdataList = [];

      //filtering data based on id //divide data
      this.props.dataSetsArray.forEach((id, index) => {
        chartdataList[index] = this.props.allChartData?.items.filter(
          (m) => m.id_container_data_latest === id
        );
      });
      const IsSplitEnergyMeterPowerPositive = this.props.chartName.includes(
        "SplitEnergyMeterPowerPositive"
      );
      const IsSplitEnergyMeterPowerNegative = this.props.chartName.includes(
        "SplitEnergyMeterPowerNegative"
      );

      if (IsSplitEnergyMeterPowerNegative) {
        formInput.chartList = chartdataList;
      }
      if (IsSplitEnergyMeterPowerPositive) {
        formInput.restChartList =
          chartdataList.length > 1 ? chartdataList.slice(1) : chartdataList;
      }
      this.setState({ formInput });
      this.setState({ formInput }, () => {
        this.props.getSeriesData(this.state.formInput.series);
      });
    }
  }

  render() {
    const formInput = { ...this.state.formInput };
    formInput.chartNameView = this.props.chartName;
    const isMobile = window.innerWidth < 768;
    formInput.chartUnit = this.props.chartUnit;
    let dataToRenderMaxValue = [];

    if (
      formInput.chartList.length !== 0 ||
      formInput.restChartList.length !== 0
    ) {
      const dataListChart =
        formInput.chartList.length !== 0
          ? formInput.chartList
          : formInput.restChartList;
      dataListChart.forEach((m, index) => {
        let filledDataArray = [];
        const timestamps = m?.map(
          (dataPoint) => dataPoint?.timestamp_seconds * 1000
        );

        const dataValues = m?.map((dataPoint) => dataPoint?.avg_val);
        const resolution = m[0]?.resolution || "day";
        const interval = resolution === "day" ? 86400000 : 3600000;

        let fromDate = this.props.chartdata.fromDate * 1000;
        fromDate = Math.floor(fromDate / interval) * interval;

        let toDate = this.props.chartdata.toDate * 1000;
        toDate = Math.floor(toDate / interval) * interval;

        const timestampDataMap = new Map();
        timestamps.forEach((timestamp, idx) => {
          timestampDataMap.set(timestamp, dataValues[idx]);
        });

        let currentTimestamp = fromDate;

        while (currentTimestamp <= toDate) {
          if (timestampDataMap.has(currentTimestamp)) {
            filledDataArray.push({
              timestamp: currentTimestamp,
              avg_val: timestampDataMap.get(currentTimestamp),
            });
          } else {
            filledDataArray.push({
              timestamp: currentTimestamp,
              avg_val: 0,
            });
          }
          currentTimestamp += interval;
        }
        timestamps.forEach((originalTimestamp, idx) => {
          if (
            !filledDataArray.some(
              (entry) => entry.timestamp === originalTimestamp
            )
          ) {
            filledDataArray.push({
              timestamp: originalTimestamp,
              avg_val: dataValues[idx],
            });
          }
        });
        filledDataArray.sort((a, b) => a.timestamp - b.timestamp);
        const allValuesAreZero = filledDataArray.every(
          (dataPoint) => dataPoint.avg_val === 0
        );
        if (allValuesAreZero) {
          filledDataArray = [];
        }
        dataToRenderMaxValue[index] = [
          ...filledDataArray?.map((dataPoint) => dataPoint?.avg_val),
        ];
        var labels = [
          ...filledDataArray?.map((dataPoint) => dataPoint?.timestamp),
        ];

        formInput.series[index] = [
          {
            name:
              formInput?.chartNameView[index] ===
              "SplitEnergyMeterPowerPositive"
                ? "Power Produced"
                : formInput?.chartNameView[index] ===
                  "SplitEnergyMeterPowerNegative"
                ? "Power Drawn"
                : "",
            data:
              formInput?.chartNameView[index] ===
              "SplitEnergyMeterPowerNegative"
                ? dataToRenderMaxValue[index].map((value) => value * -1)
                : dataToRenderMaxValue[index],
            type: "line",
            toolbar: {
              title: {
                text: formInput?.chartUnit[index],
              },
            },
            labels: labels,
          },
        ];
        formInput.options[index] = {
          chart: {
            height: 750,
            type: "line",
            toolbar: {
              show: false,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: false,
            },
          },
          noData: {
            fontSize: "16px",
            text: "No data available",
            align: "center",
            verticalAlign: "middle",
          },
          dataLabels: {
            enabled: false,
          },
          colors: [
            formInput?.chartNameView[index] === "SplitEnergyMeterPowerPositive"
              ? "#FF9A00"
              : formInput?.chartNameView[index] ===
                "SplitEnergyMeterPowerNegative"
              ? "#8C57FF"
              : "",
          ],
          title: {
            text:
              formInput?.chartNameView[index] ===
              "SplitEnergyMeterPowerPositive" // ActiveExportEnergy SplitEnergyMeterPowerPositive
                ? "Power Produced (Energy Meter)"
                : formInput?.chartNameView[index] ===
                  "SplitEnergyMeterPowerNegative" //ActiveImportEnergy SplitEnergyMeterPowerNegative
                ? "Power Drawn (Energy Meter)"
                : "",
            align: "left",
            style: {
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: isMobile ? "550" : "700",
              fontFamily: "Roboto Flex, sans-serif",
            },
            floating: false,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            fontFamily: "Roboto Flex",
            fontSize: "14px",
            fontWeight: "100",
            height: 40,
            labels: {
              colors: ["#fff", "#fff", "#fff"],
            },
            formatter: (seriesName) => {
              const color =
                formInput?.chartNameView[index] ===
                "SplitEnergyMeterPowerPositive"
                  ? "#FF9A00"
                  : formInput?.chartNameView[index] ===
                    "SplitEnergyMeterPowerNegative"
                  ? "#8C57FF"
                  : "";
              const col = "#fff";
              return `<span class="legend-item" style="background-color: ${color};color: ${col};">${seriesName}</span>`;
            },
            markers: {
              width: 0,
              height: 0,
              radius: 0,
            },
          },
          tooltip: {
            enabled: true,
            theme: "light",
            x: {
              format: "HH:mm  dd/MM/yyyy",
            },
            y: {
              formatter: function (value) {
                return value
                  ? value.toFixed(2) + " " + formInput?.chartUnit[index]
                  : "";
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName + ": ";
                },
              },
            },
          },
          xaxis: {
            tooltip: {
              enabled: false,
              formatter: function (val) {
                return new Date(val).toLocaleString();
              },
            },
            categories: labels,
            type: "datetime",
            tickPlacement: "on",
            tickAmount: labels.length,
            labels: {
              datetimeUTC: false,
              showDuplicates: false,
            },
          },
          yaxis: {
            title: {
              text: formInput?.chartUnit[index],
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            labels: {
              formatter: (value) => {
                return Number.isInteger(value)
                  ? value?.toFixed(0)
                  : value?.toFixed(2);
              },
            },
          },
        };
      });
    }

    return (
      <Fragment>
        <div className="chartSett chart-margin-bottom">
          <div className="container-top">
            {formInput.chartList.length > 0 &&
            formInput.chartList[0]?.length > 0 ? (
              formInput.chartList.map((l, index) => (
                <div
                  className={
                    index === 0
                      ? "bacecard-graph-chart col-lg-7 top-chart power_drawn"
                      : "col-lg weatherReport"
                  }
                >
                  {index === 0 && (
                    <div>
                      {" "}
                      <Chart
                        options={formInput.options[index]}
                        series={formInput.series[index]}
                        height={300}
                        width="100%"
                        marginLeft={12}
                      />
                    </div>
                  )}
                  {index !== 0 && (
                    <div className="">
                      <WeatherStatus />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div style={{ display: "contents" }}>
                {" "}
                <div className="bacecard-graph-chart col-lg-7 top-chart">
                  No data available for Power Drawn
                </div>{" "}
                <div className="col-lg weatherReport">
                  {" "}
                  <div className="">
                    <WeatherStatus />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="container-top">
            {formInput.restChartList.length > 0 &&
            formInput.restChartList[0]?.length > 0 ? (
              formInput.restChartList.map((l, index) => (
                <div
                  className={
                    index === 0
                      ? "bacecard-graph-chart col-lg-7 top-chart power-production"
                      : "col-lg weatherReport"
                  }
                  key={index}
                  ref={(el) => (this.chartRefs[index] = el)}
                >
                  {formInput.chartList.length > 0 ? (
                    <div>
                      {" "}
                      <Chart
                        options={formInput.options[index + 1]}
                        series={formInput.series[index + 1]}
                        height={300}
                        width="100%"
                        marginLeft={12}
                      />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Chart
                        options={formInput.options[index]}
                        series={formInput.series[index]}
                        height={300}
                        width="100%"
                        marginLeft={12}
                      />
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div style={{ display: "contents" }}>
                {" "}
                <div className="bacecard-graph-chart col-lg-7 top-chart">
                  No data available for Power Production
                </div>{" "}
              </div>
            )}
            <div className="col-lg weatherReport">
              <SolarIrradiance />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { allChartData: state.listViewReducer?.allChartData };
}

export default withRouter(
  connect(mapStateToProps, { clearProjectData })(HomeChart)
);
